import React from 'react'
import  { useEffect, useContext, useState } from 'react'
import HandleContext from "../../context/HandleContext";
import Logo1 from '../../images/SV_01.png'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom';

export default function Navbar() {
    const context = useContext(HandleContext);
  const { GetData, userProfile, Call ,profile,UserDashboard,userInvestment} = context;

  const login = () => {
    navigate('/login')
}

  useEffect(() => {
    GetData('profile')
    Cookies.get('num') ? UserDashboard("dashboard") : navigate('/login')
    Cookies.get('num') ? GetData("profile") : navigate('/login')
}, []);
console.log(profile);
  let navigate=useNavigate()
  const logout = () => {
    Cookies.remove('num')
    Cookies.remove('id')
    Cookies.remove('first_name')
    Cookies.remove('last_name')
    Cookies.remove('phone_no')
    Cookies.remove('address')
    Cookies.remove('city')
    Cookies.remove('state')
    Cookies.remove('country')
    Cookies.remove('pincode')
    Cookies.remove('gst_number')
    Cookies.remove('adnum')
    Cookies.remove('banking')
    Cookies.remove('account')
    Cookies.remove('ifsc')
    Cookies.remove('accountHolder')
    Cookies.remove('bankName')
  }
  return (
 <div>
  <div className="navigation-wrap bg-light start-header start-style border-bottom  sticky-top">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <nav className="navbar navbar-expand-md navbar-light">
            <img src={Logo1} className="img-fluid sunstox-main-logo" alt="logo" style={{width:'150px',height:'50px'}}/>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto py-4 py-md-0">
                <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                  <Link className="nav-link" to="/dashboard">Dashboard</Link>
                </li>
                <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                  <Link className="nav-link" to="/">Solar Project</Link>
                </li>
                <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                  <a className="nav-link" href="https://sunstox.com/how-it-works/">How it work</a>
                </li>
                <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                  <a className="nav-link" href="https://sunstox.com/blog">Blogs</a>
                </li>
                <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4 active">
                  <a className="nav-link" href="https://sunstox.com/about/">About us</a>
                </li>
                {Cookies.get('num')?
                <div className="dropdown">
                  <button className="btn dropdown-toggle dashboard-profile-icon" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fas fa-user-circle" style={{fontSize: '2rem'}} />
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <Link className="dropdown-item" to="/profile"><i className="fas fa-user" /> &nbsp;{Cookies.get('name')}</Link>
                    <Link className="dropdown-item" to="/setting"><i className="fas fa-cog" /> &nbsp; Settings</Link>
                    <div onClick={logout} className="dropdown-item" href="#"><i className="fas fa-sign-out-alt" /> &nbsp;<a href='https://sunstox.com/' style={{color:'black',textDecoration:'none'}}> Logout</a></div>
                  </div>
                </div>:  <div className="dropdown nav-item nav-link " >
                                    <a className='dropbtn mainLinks loginbtn' onClick={login} >Login </a>
                                </div>}
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}
