import React, { useEffect, useContext, useState } from 'react'
import Tab from '../Tab'
import Appbar from '../Appbar'
import HandleContext from '../../context/HandleContext';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";


export default function Aadhar() {
  

  const navigate = useNavigate();
  const context = useContext(HandleContext);
  const { Aadhaar, status,GetData,userProfile } = context;

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    GetData('profile')
    Cookies.get('status') ? navigate('/verification') : setLoading(false);
  }, [Aadhaar])

  // useEffect(() => {
  //   GetData('profile')
  // }, [userProfile])


  const [credentials, setCredentials] = useState({ aadhaar: "" })

  const OnChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    Aadhaar(JSON.stringify(credentials), 'aadhaarotp')
    Cookies.set('adnum', credentials.aadhaar)
    setLoading(true);
  }

  
  return (
    <div>
       
      <div className='container' style={{ margin: "10px 0px" }}>
        <h5 style={{marginBottom:'20px',fontSize: '20px', fontWeight: '400' }}>KYC Details 
        {/* <span style={{marginLeft:'8px'}} title="Lorem Ipsum is simply dummy text of the printing and typesetting industry." className='tool'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                    </span> */}
                    </h5>
        {userProfile && userProfile.adhaar?
        <>
 <div className="form-group modal_div">
                    <label htmlFor="exampleInputEmail1" style={{ fontWeight: '700' }}>Aadhaar Number</label>
                    <div style={{ fontWeight: '600' }}>{userProfile.adhaar} </div>
                </div>
        </>
        :<form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Enter Your Aadhaar Number</label>
            <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="" required name='aadhaar' value={credentials.aadhaar} onChange={OnChange} />
          </div>

          <button type="submit" className="btn btn-primary" style={{background:'#ff9c00',borderColor:'#ff9c00'}}>
            {loading === true ?
              <ClipLoader
                color="white"
                size={15}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              : 'Submit'}</button>
        </form>}
      </div>

    </div>
  )
}
