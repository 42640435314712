// import './App.css';
import {BrowserRouter, Routes, Route,} from "react-router-dom";
import Signup from './components/Signup';
import HandleState from './context/HandleState';
import Login from './components/Login';
import Forgotpassword from './components/Forgotpassword';
// import Changepassword from './components/Changepassword'
import Tab from './components/Tab';
import Solar from './components/Projects/Solar'
import Completed from './components/Projects/Completed'
import DetailedProject from './components/Projects/DetailedProject';
// import Appbar from "./components/Appbar";
import Payment from "./components/Projects/Payment";
import DetailedProject2 from "./components/Projects/DetailedProject2";
// import User_Dashboard from "./components/Projects/User_Dashboard";
import Payment_History from "./components/Projects/Payment_History"
import Payout_Generated from "./components/Projects/Payout_Generated";
import Payout_Generation from './components/Projects/Payout_Generation'
// import Profile from "./components/Profile";
// import Navbar from "./components/Appbar";
import Activation from "./components/Activation";
import Pancard from "./components/Projects/Pancard";
import Aadhar from "./components/Projects/Aadhar";
import AadharVerification from "./components/Projects/AadharVerification";
import Account from "./components/Projects/Account";
import Updation from "./components/Projects/Updation";
//new
import Dashboard from "./components/new-pages/Dashboard";
import Navbar from "./components/new-pages/Navbar";
import Profile from "./components/new-pages/Profile";
import Aboutus from "./components/new-pages/Aboutus";
import Contactus from "./components/new-pages/Contactus";
import Setting from "./components/new-pages/Setting";

function App() {
  return (
    <div >
      <HandleState>
     <BrowserRouter>
      <Routes>   
        {/* <Route exact path="/" element={<Main />}></Route> */}
        <Route exact path="/signup" element={<Signup />}></Route>
        <Route exact path="/login" element={<Login />}></Route>
        <Route exact path="/forgotpassword" element={<Forgotpassword />}></Route>
        <Route exact path="/activation" element={<Activation />}></Route>
        {/* <Route exact path="/changepassword" element={<Changepassword />}></Route> */}
        {/* <Route exact path="/tab" element={<Tab />}></Route> */}
        <Route exact path="/" element={<Solar />}></Route>
        <Route exact path="/completed" element={<Completed />}></Route>
        <Route exact path="/details" element={<DetailedProject />}></Route>
        {/* <Route exact path="/details2" element={<DetailedProject2 />}></Route> */}
        {/* <Route exact path="/appbar" element={<Appbar />}></Route> */}
        <Route exact path="/navbar" element={<Navbar />}></Route>
        <Route exact path="/dashboard" element={<Dashboard />}></Route>
        <Route exact path="/profile" element={<Profile />}></Route>
        <Route exact path="/aboutus" element={<Aboutus />}></Route>
        <Route exact path="/contactus" element={<Contactus />}></Route>
        <Route exact path="/setting" element={<Setting />}></Route>

        <Route exact path="/pay" element={<Payment />}></Route>
        {/* <Route exact path="/user-dashboard" element={<User_Dashboard />}></Route> */}
        <Route exact path="/payment-history" element={<Payment_History />}></Route>
        <Route exact path="/payout-gen" element={<Payout_Generated />}></Route>
        <Route exact path="/payout-generation" element={<Payout_Generation />}></Route>
        {/* <Route exact path="/profile" element={<Profile />}></Route> */}
        {/* <Route exact path="/navbar" element={<Navbar />}></Route> */}
        <Route exact path="/aadhar" element={<Aadhar />}></Route>
        <Route exact path="/verification" element={<AadharVerification />}></Route>
        <Route exact path="/pancard" element={<Pancard />}></Route>
        <Route exact path="/account" element={<Account />}></Route>
        {/* <Route exact path="/update" element={<Updation />}></Route> */}
        </Routes>
        </BrowserRouter>
        </HandleState>
    </div>
  );
}

export default App;
