import React, { useState } from 'react'
import HandleContext from './HandleContext'
import axios from 'axios'
import Cookies from 'js-cookie';
import toastr from 'toastr'
import parse from 'html-react-parser';

export default function HandleState(props) {

    const [status, setStatus] = useState(false)
    const [userProfile, setUserProfile] = useState()
    const [project, setProject] = useState()
    const [details, setDetails] = useState()
    const [userDashboard, setUserDashboard] = useState()
    const [userInvestment, setUserInvestment] = useState()
    const [paymentHistory, setPaymentHistory] = useState()
    const [paymentGeneration, setPaymentGeneration] = useState()

    let url = "https://api.sunstox.com/user";
    let headers = {
        'Content-Type': 'application/json',
        'Client-Service': 'sunstoxAPP',
        'Auth-Key': '256901zrj4UyYF2SqzoAAZpqf67',
        'uid': Cookies.get('id'),
        'token': Cookies.get('num')
    }

    const Login = async (data) => {
        await axios.post(`${url}/login`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Client-Service': 'sunstoxAPP',
                'Auth-Key': '256901zrj4UyYF2SqzoAAZpqf67',
            }
        }).then((response) => {
            toastr.success(response.data.message)
            Cookies.set("id", response.data.uid)
            Cookies.set("num", response.data.token)
            // console.log(response.data.message);
        }).catch((e) => {
            toastr.error(e.response.data.message)
            // console.log(e.response.data.message);
        })
    }

    const Call = async (data, id) => {
        await axios.post(`${url}/${id}`, data, {
            headers: headers
        }).then((response) => {
            toastr.success(response.data.message)
            console.log(response);
        }).catch((e) => {
            // console.log(e);
            toastr.error(e.response.data.message)
            console.log(e);
        })
    }

    const Aadhaar = async (data, id) => {
        await axios.post(`${url}/${id}`, data, {
            headers: headers
        }).then((response) => {
            setStatus(true)
            console.log(response);
            toastr.success(response.data.data.message)
            Cookies.set('ref_id',response.data.data.ref_id)
            Cookies.set('status',true)
        }).catch((e) => {
            console.log(e);
            toastr.error(e.response.data.message)
        })
    }
    const AadhaarVerification = async (data, id) => {
        await axios.post(`${url}/${id}`, data, {
            headers: headers
        }).then((response) => {
            setStatus(true)
            console.log(response);
            toastr.success(response.data.data.message)
            Cookies.set('ref_id',response.data.data.ref_id)
            Cookies.set('verify',true)
        }).catch((e) => {
            console.log(e);
            toastr.error(e.response.data.data.message)
            Cookies.set('verify',true)

        })
    }

    const Bank = async (data, id) => {
        await axios.post(`${url}/${id}`, data, {
            headers: headers
        }).then((response) => {
            // toastr.success(response.data.message)
            setStatus(true)
            console.log(response.data.data.nameAtBank);
            console.log(response.data.data.branch);
            Cookies.set('accountHolder',response.data.data.nameAtBank)
            Cookies.set('bankName',response.data.data.bankName)
            Cookies.set('branch',response.data.data.branch)
            Cookies.set('banking',true)
        }).catch((e) => {
            console.log(e);
            toastr.error(e.response.data.message)
        })
    }
    const BankUpdate = async (data, id) => {
        await axios.post(`${url}/${id}`, data, {
            headers: headers
        }).then((response) => {
            toastr.success(response.data.message)
            setStatus(true)
            Cookies.set('accountHolder',response.data.data.nameAtBank)
            Cookies.set('bankName',response.data.data.bankName)
            Cookies.set('branch',response.data.data.branch)
            Cookies.set('banking',true)
        }).catch((e) => {
            console.log(e);
            toastr.error(e.response.data.message)
        })
    }

    const GetData = async (id) => {
        let response = await fetch(`${url}/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Client-Service': 'sunstoxAPP',
                'Auth-Key': '256901zrj4UyYF2SqzoAAZpqf67',
                'uid': Cookies.get('id'),
                'token': Cookies.get('num')
            }
        })
        let json = await response.json()
        // console.log(json);
        setUserProfile(json.data)
        // console.log(json.data.first_name);
        Cookies.set('first_name',json.data.first_name)
        Cookies.set('last_name',json.data.last_name)
        Cookies.set('phone_no',json.data.phone_no)
        Cookies.set('address',json.data.address)
        Cookies.set('city',json.data.city)
        Cookies.set('state',json.data.state)
        Cookies.set('country',json.data.country)
        Cookies.set('pincode',json.data.pincode)
        Cookies.set('gst_number',json.data.gst_number)
        // console.log(userProfile);
    }

    const Projects = async (id) => {
        let response = await fetch(`https://api.sunstox.com/site/${id}`, {
            headers: headers,
        })
        let json = await response.json()
        setProject(json.data)
    }

    const ProjectDetails = async (id) => {
        let response = await fetch(`https://api.sunstox.com/user/project_detail/${id}`, {
            headers: headers,
        })
        let json = await response.json()
        // console.log(response);
        setDetails(json.data)
    }

    const PaymentGeneration = async (id) => {
        let response = await fetch(`https://api.sunstox.com/user/payout_generation/${id}`, {
            headers: headers,
        })
        let json = await response.json()
        // console.log(json);
        setPaymentGeneration(json.data)
    }

    const UserDashboard = async (id) => {
        let response = await fetch(`${url}/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Client-Service': 'sunstoxAPP',
                'Auth-Key': '256901zrj4UyYF2SqzoAAZpqf67',
                'uid': Cookies.get('id'),
                'token': Cookies.get('num')
            },
        })
        let json = await response.json()
        // console.log(json);
        setUserInvestment(json.data)
        setUserDashboard(json.data.data)
    }

    const PaymentHistory = async (id) => {
        let response = await fetch(`${url}/${id}`, {
            headers: headers,
        })
        let json = await response.json()
        setPaymentHistory(json.data)
    }

    const RequestPayout = async (data, id) => {
        await axios.post(`${url}/${id}`, data, {
            headers: headers
        }).then((response) => {
            toastr.success(response.data.message)
        }).catch((e) => {
            toastr.error(e.response.data.message)
        })
    }

    return (
        <HandleContext.Provider value={{
            Login, Call, GetData, userProfile, Projects, project, ProjectDetails, details, UserDashboard, userDashboard, userInvestment, PaymentHistory, paymentHistory, PaymentGeneration, paymentGeneration, RequestPayout,Aadhaar,status,Bank,BankUpdate,AadhaarVerification
        }}>
            {props.children}
        </HandleContext.Provider>
    )
}
