import React,{useContext,useEffect,useState} from 'react'
import InfoCard from './InfoCard'
import Appbar from '../Appbar'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { AppBar, Toolbar, Box, TextField, InputLabel, Input, FormControl } from "@mui/material";
import HandleContext from "../../context/HandleContext";
import Cookies from 'js-cookie';


export default function Payment() {

    const [credentials, setCredentials] = useState({ no_of_cells: "1" })

    const onChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value })
    }


    const formik = useFormik({
        initialValues: {
          cells: "",
          rateCell: "",
        },
        validationSchema: Yup.object({
          cells: Yup.string().required("No. Of Cells are required"),
          rateCell: Yup.string().required("rateCell is Required"),
        }),
        onSubmit: async (values) => {
          // console.log("form submitted", values)
          await axios.post(`https://api.sunstox.com/user/login`, JSON.stringify(values), {
            headers: {
              'Content-Type': 'application/json',
              'Client-Service': 'sunstoxAPP',
              'Auth-Key': '256901zrj4UyYF2SqzoAAZpqf67',
            }
          }).then((response) => {
            // console.log(response);
          
          }).catch((e) => {
            // console.log(e);
           
          })
    
        }
      })
      const context = useContext(HandleContext);
      const { ProjectDetails, details } = context;
  
      useEffect(() => {
          // console.log('Detail project page');
          ProjectDetails(Cookies.get('ProjectId'))
      }, [])
  return (
    <>
    <Appbar/>
    <div className="container">
        <div className="row">
            <div className="col-md-7">
                    <div style={{fontSize:'30px',margin:'30px 0px',fontWeight:'400' ,color:'black'}}>Buy and earn income from solar cells</div>
                    <hr />
                    <div style={{color:'#fab600' ,fontSize:'25px'}}>Baysville School of Skills</div>
                    <div style={{fontSize:'15px',margin:'20px 0px 30px 0px'}}>Buy up to 1 cell with your available balance.</div>
                    <form action='https://api.sunstox.com/razorpay' method='post'>
                        <div style={{}}>
                    <TextField
                      required
                      id="filled"
                      label="Number of cells"
                      name="no_of_cells"
                      variant="filled"
                      value={credentials.no_of_cells}
                      sx={{ width: "100%" ,marginBottom:'20px'}}
                      onChange={onChange}
                    />
                    <TextField
                      required
                      id="filled-read-only-input"
                      label="Total Amount"
                      name="rateCell"
                      variant="filled"
                      defaultValue="Coonection Interrupted"
                      value={details && (details.rate_cell*credentials.no_of_cells)}
                      sx={{ width: "100%" }}
                      InputProps={{
                        readOnly: true
                    }}
                    />
                    <input type="hidden" name="user_id" value={Cookies.get('id')}/>
                    <input type="hidden" name="project_id" value={details && details.id}/>
                    <input type="hidden" name="return_url" value="https://login.sunstox.com"/>
                    </div>
                    <div style={{textAlign:'center',marginTop:'40px'}}>
                    <button type='submit' className='btn' style={{ padding: '10px 20px', backgroundColor: '#fab600', outline: 'none', background: '#fab600', color: 'white', fontWeight: '500', margin: '500' }}>BUY SOLAR CELLS NOW </button></div>
                     </form>
            </div>
            {/* <div className="col-md-5">
<InfoCard/>
            </div> */}
        </div>
    </div>
    </>
  )
}
