import React,{useEffect,useContext} from 'react'
import HandleContext from "../../context/HandleContext";
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Appbar from '../Appbar';
import Navbar from '../new-pages/Navbar';

export default function Payout_Generation() {
  let count=1;
  let navigate=useNavigate()
  const context = useContext(HandleContext);
  const { PaymentGeneration, paymentGeneration } = context;

  useEffect(() => {
    // console.log('Detail project page');
        Cookies.get('num') ? PaymentGeneration(Cookies.get('ProjectId')):navigate('/login')
        // console.log('called');
}, [])

// paymentGeneration ? console.log(paymentGeneration) : console.log('no details');
  return (
    <>
     <Navbar />
            <div className="container-fluid ">
                <div className="row ">
                    <div className="col-md-12">
                        <div className="card " style={{marginTop:'20px'}}>
                            <div className="card-header card-secondary" style={{color:'grey'}}>Payout Generation</div>
                            <div className="card-body table-body">
                            <table className="table table-striped table-hover table-bordered ">
                                <thead>
                                    <tr>
                                        <th scope="col">S. No.</th>
                                        <th scope="col">Project Title</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Month</th>
                                        <th scope="col">Generated Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paymentGeneration && paymentGeneration.map((e)=>{
                                        return <tr key={e.id}>
                                         <th scope="row">{count++}</th>
                                         <td>{e.project_title}</td>
                                         <td>{e.amount}</td>
                                         <td>{e.month}</td>
                                         <td>{e.gen_date}</td>
                                     </tr>
                                    })}
                                   
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}
