import React from 'react'
import Navbar from './Navbar'

export default function Contactus() {
  return (
   <div>
    <Navbar/>
  <navbar>
    <section>
      <div className="container-fluid mt-3">
        <h5 className="color-theme text-center">Contact us</h5>
        <h5 className="color-theme text-center">Any Queries or Remarks? Just Write us a Message</h5>
      </div>
    </section>
    <section>
      <div className="container-fluid mt-3">
        <div className="row ">
          <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
            <div className="card profile-card">
              <div className="card-header text-center profile-card-header">
                <h5 className="text-center">Contact Information</h5>
              </div>
              <div className="card-body profile-card-body">
                <div className="row">
                  <div className="col-10">
                    <h5 className="m-0 font-17"><i className="fas fa-phone" /> &nbsp; +91 897 789 2727</h5>
                  </div>
                </div>
              </div>
              <div className="card-body profile-card-body">
                <div className="row">
                  <div className="col-10">
                    <h5 className="m-0 font-17"><i className="fas fa-envelope" /> &nbsp; ab@gmail.com</h5>
                  </div>
                </div>
              </div>
              <div className="card-body profile-card-body">
                <div className="row">
                  <div className="col-10">
                    <h5 className="m-0 font-17"><i className="fas fa-map-marker-alt" /> &nbsp; AKM Tower,Near Ganpati Dham Bhahdurgarh,Haryana</h5>
                  </div>
                </div>
              </div>
              <div className="card-footer" style={{height: '10.5rem', backgroundColor: 'white'}}>
                <ul className=" d-flex ul-direction">
                  {/*  <li class="li-type"><i class="fas fa-envelope icon-bg"></i></li> */}
                  <li className="li-type"><i className="fab fa-facebook-f icon-bg" /></li>
                  <li className="li-type"><i className="fab fa-twitter icon-bg" /></li>
                  <li className="li-type"><i className="fab fa-linkedin-in icon-bg" /></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12">
            <div className="card profile-main-card">
              <div className="card-header profile-card-header">
                <h6>Any  Queries</h6>
                <p><i className="fas fa-info-circle" /> &nbsp; Fill up the Form &amp; our Team will get  back to you soon.</p>
              </div>
              <div className="card-body">
                <form className="profile-form">
                  <div className="row">
                    <div className="col-lg-4 col-xl-4 col-md-4 col-sm-12">
                      <div className="form-group">
                        <label className="form-label">First Name</label>
                        <input type="text" className="form-control" name="First Name" placeholder="Ashmita" />
                      </div>
                    </div>
                    <div className="col-lg-4 col-xl-4 col-md-4 col-sm-12">
                      <div className="form-group">
                        <label className="form-label">Last Name</label>
                        <input type="text" className="form-control" name="Last Name" placeholder="Sharma" />
                      </div>
                    </div>
                    <div className="col-lg-4 col-xl-4 col-md-4 col-sm-12">
                      <div className="form-group">
                        <label className="form-label">Email</label>
                        <input type="text" className="form-control" name="validation-email" placeholder="ashmita@gmail.com" />
                      </div>
                    </div>   
                    <div className="col-lg-4 col-xl-4 col-md-4 col-sm-12">
                      <div className="form-group">
                        <label className="form-label">Phone</label>
                        <input type="text" className="form-control" name="validation-phone" placeholder="Phone: (9+) 999-9999" />
                      </div>
                    </div>
                    <div className="col-lg-8 col-xl-8 col-md-8 col-sm-12">
                      <div className="form-group">
                        <label className="form-label">Queries</label>
                        <textarea className="form-control" rows={5} id="comment" name="text" placeholder="Write your  message here..." defaultValue={""} />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-xl-4 col-md-4 col-sm-12">
                    <button type="submit" className="btn profile-card-btn mr-3">Send message</button>
                    <button type="submit" className="btn profile-card-btn">Reset</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </navbar></div>

  )
}
