import React from 'react'
import Navbar from './Navbar'
import $ from 'jquery'
import jQuery from 'jquery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import HandleContext from '../../context/HandleContext';
import AppBar from '../Appbar'
import { useContext, useEffect } from 'react';
import parse from 'html-react-parser'
import { CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import Solar from '../Projects/Solar';
import Profile from '../Profile'
import Updation from '../Projects/Updation'
import Pancard from '../Projects/Pancard'
import Aadhar from '../Projects/Aadhar'
import Account from '../Projects/Account'

export default function Dashboard() {

  // let navigate=useNavigate()
  const [alert, setAlert] = useState()
  const [message, setMessage] = useState()



  const [credentials, setCredentials] = useState({ amount: "" })
  const handleChange = (e) => {
      setCredentials({ ...credentials, [e.target.name]: e.target.value });
  }
  const handleSubmit = () => {
      RequestPayout(JSON.stringify(credentials), 'request_payout')
  }
  let navigate = useNavigate()
  const theme = useTheme()

  const context = useContext(HandleContext);
  const { UserDashboard,GetData, userDashboard, userInvestment, PaymentGeneration, PaymentHistory, RequestPayout } = context;
  useEffect(() => {
      Cookies.get('num') ? UserDashboard("dashboard") : navigate('/login')
      GetData('profile')
      const queryParams = new URLSearchParams(window.location.search);
      const status = queryParams.get('s');
      const message = queryParams.get('m');
      // console.log(status);
      if (status == 'success') {
          setAlert('success')
          setMessage(message)
      } else {
          setAlert('danger')
          setMessage(message)
      }
  }, [])

    $(document).ready(function () {
        $('[data-toggle="tooltip"]').tooltip();
    });

    jQuery(document).ready(function() {
        // click on next button
        jQuery('.form-wizard-next-btn').click(function() {
            var parentFieldset = jQuery(this).parents('.wizard-fieldset');
            var currentActiveStep = jQuery(this).parents('.form-wizard').find('.form-wizard-steps .active');
            var next = jQuery(this);
            var nextWizardStep = true;
            parentFieldset.find('.wizard-required').each(function(){
                var thisValue = jQuery(this).val();
    
                if( thisValue == "") {
                    jQuery(this).siblings(".wizard-form-error").slideDown();
                    nextWizardStep = false;
                }
                else {
                    jQuery(this).siblings(".wizard-form-error").slideUp();
                }
            });
            if( nextWizardStep) {
                next.parents('.wizard-fieldset').removeClass("show","400");
                currentActiveStep.removeClass('active').addClass('activated').next().addClass('active',"400");
                next.parents('.wizard-fieldset').next('.wizard-fieldset').addClass("show","400");
                jQuery(document).find('.wizard-fieldset').each(function(){
                    if(jQuery(this).hasClass('show')){
                        var formAtrr = jQuery(this).attr('data-tab-content');
                        jQuery(document).find('.form-wizard-steps .form-wizard-step-item').each(function(){
                            if(jQuery(this).attr('data-attr') == formAtrr){
                                jQuery(this).addClass('active');
                                var innerWidth = jQuery(this).innerWidth();
                                var position = jQuery(this).position();
                                jQuery(document).find('.form-wizard-step-move').css({"left": position.left, "width": innerWidth});
                            }else{
                                jQuery(this).removeClass('active');
                            }
                        });
                    }
                });
            }
        });

       
        //click on previous button
        jQuery('.form-wizard-previous-btn').click(function() {
            var counter = parseInt(jQuery(".wizard-counter").text());;
            var prev =jQuery(this);
            var currentActiveStep = jQuery(this).parents('.form-wizard').find('.form-wizard-steps .active');
            prev.parents('.wizard-fieldset').removeClass("show","400");
            prev.parents('.wizard-fieldset').prev('.wizard-fieldset').addClass("show","400");
            currentActiveStep.removeClass('active').prev().removeClass('activated').addClass('active',"400");
            jQuery(document).find('.wizard-fieldset').each(function(){
                if(jQuery(this).hasClass('show')){
                    var formAtrr = jQuery(this).attr('data-tab-content');
                    jQuery(document).find('.form-wizard-steps .form-wizard-step-item').each(function(){
                        if(jQuery(this).attr('data-attr') == formAtrr){
                            jQuery(this).addClass('active');
                            var innerWidth = jQuery(this).innerWidth();
                            var position = jQuery(this).position();
                            jQuery(document).find('.form-wizard-step-move').css({"left": position.left, "width": innerWidth});
                        }else{
                            jQuery(this).removeClass('active');
                        }
                    });
                }
            });
        });
        //click on form submit button
        jQuery(document).on("click",".form-wizard .form-wizard-submit" , function(){
            var parentFieldset = jQuery(this).parents('.wizard-fieldset');
            var currentActiveStep = jQuery(this).parents('.form-wizard').find('.form-wizard-steps .active');
            parentFieldset.find('.wizard-required').each(function() {
                var thisValue = jQuery(this).val();
                if( thisValue == "" ) {
                    jQuery(this).siblings(".wizard-form-error").slideDown();
                }
                else {
                    jQuery(this).siblings(".wizard-form-error").slideUp();
                }
            });
        });
        // focus on input field check empty or not
        jQuery(".form-control").on('focus', function(){
            var tmpThis = jQuery(this).val();
            if(tmpThis == '' ) {
                jQuery(this).parent().addClass("focus-input");
            }
            else if(tmpThis !='' ){
                jQuery(this).parent().addClass("focus-input");
            }
        }).on('blur', function(){
            var tmpThis = jQuery(this).val();
            if(tmpThis == '' ) {
                jQuery(this).parent().removeClass("focus-input");
                jQuery(this).siblings('.wizard-form-error').slideDown("3000");
            }
            else if(tmpThis !='' ){
                jQuery(this).parent().addClass("focus-input");
                jQuery(this).siblings('.wizard-form-error').slideUp("3000");
            }
        });
    });

    let navi=()=>{
      navigate('/payout-generation')
    }
  return (
   <div>
    <Navbar/>
  {/*main content starts here*/}
  {/* main card div sec starts here*/}
  <section>
    <div className="container-fluid mt-3">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-5 col-lg-5 dashboard-main-card">
          <div className="row col-12">
            <div className="col-sm-12 col-md-6 col-xl-6 col-lg-6">
              <div className="card comp-card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col">
                      <h5 className="m-b-20 font-18 font-400">
                        <a href="#" data-toggle="tooltip" data-placement="bottom" title="The number of units of solar electricity your cells have produced"><i className="fas fa-info-circle info-icon" /></a> &nbsp;
                        Invested
                      </h5>
                      <h5 className="text-c-blue" style={{marginLeft: '2rem'}}> {userInvestment && userInvestment.total_investment}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-xl-6 col-lg-6">
              <div className="card comp-card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col">
                      <h5 className="m-b-20 font-18 font-400">
                        <a href="#" data-toggle="tooltip" data-placement="bottom" title="C02 footprint reduced by using solar cells"><i className="fas fa-info-circle info-icon" /></a> &nbsp;                                         Cells Bought

                      </h5>
                      <h5 className="text-c-blue" style={{marginLeft: '2rem'}}>       {userInvestment && userInvestment.total_cells_bought}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-xl-6 col-lg-6">
              <div className="card comp-card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col">
                      <h5 className="m-b-20 font-18 font-400">
                        <a href="#" data-toggle="tooltip" data-placement="bottom" title="The combined rated power output of your operating solar cells"><i className="fas fa-info-circle info-icon" /></a> &nbsp;
                        Payout Generated
                      </h5>
                      <h5 className="text-c-blue" style={{marginLeft: '2rem'}}>                                        {userInvestment && userInvestment.total_payout_generated}
</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-xl-6 col-lg-6">
              <div className="card comp-card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div>
                      <h5 className="font-18 font-400">
                        <a href="#" data-toggle="tooltip" data-placement="bottom" title="the number of solar cells you own"><i className="fas fa-info-circle info-icon" /></a> &nbsp;                                         Payout Recieved

                      </h5>
                      <h5 className="text-c-blue" style={{marginLeft: '2rem'}}>                                        {userInvestment && userInvestment.payout_received}
</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-xl-6 col-lg-6">
              <div className="card comp-card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col">
                      <h5 className="m-b-20 font-18 font-400">
                        <a href="#" data-toggle="tooltip" data-placement="bottom" title="the amount you have have earned in each wallet from your solar cells so far">
                          <i className="fas fa-info-circle info-icon" />
                        </a>
                        &nbsp;                                         Payout Available

                      </h5>
                      <h5 className="text-c-blue" style={{marginLeft: '2rem'}}>                                        {userInvestment && userInvestment.payout_available}
</h5>
                    </div>
                  </div>
          
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-xl-6 col-lg-6">
              <div className="card comp-card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div>
                      <h5 className="m-b-20 font-18 font-400">
                        <a href="#" data-toggle="tooltip" data-placement="bottom" title="The amount paid when purchasing your solar cells"><i className="fas fa-info-circle info-icon" /></a> &nbsp;                                         Payout Inprocess

                      </h5>
                      <h5 className="text-c-blue" style={{marginLeft: '2rem'}}>                                        {userInvestment && userInvestment.payout_inprocess}
</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-xl-6 col-lg-6">
          <div className="card">
            <div className="card-header color-theme">Investment Detail</div>
            {userDashboard ? userDashboard.map((e) => {
              console.log(e);
            return <div className="card-body border-bottom">
              <p style={{cursor:'pointer'}} onClick={navi}>{e.project_title}</p>
              <div className="d-flex">
                <div className="invest-card">
                  <p>Total Investment</p>
                  <p>{e.amount_invested}</p>
                </div>
                &nbsp;
                <div className="invest-card">
                  <p>Cells Bought</p>
                  <p>{e.cells_bought}</p>
                </div>
                &nbsp;
                <div>
                  <p>Payout Generated</p>
                  <p>{e.payout}</p>
                </div>
              </div>
            </div>
            // <div className="card-body">
            //   <p>Soluplus</p>
            //   <div className="d-flex">
            //     <div className="invest-card">
            //       <p>Invested</p>
            //       <p>€1.051.551</p>
            //     </div>
            //     &nbsp;
            //     <div className="invest-card">
            //       <p>Investors</p>
            //       <p>535</p>
            //     </div>
            //     &nbsp;
            //     <div>
            //       <p>Days Left</p>
            //       <p>89</p>
            //     </div>
            //   </div>
            // </div>
          }) :''}
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* main card div sec ends here*/}
  {/*filter tab sec starts here*/}
  <section className="dashboard-main-card mt-3">
    <div className="container area-master-container">
      <div className="col-sm-12">
        <ul className="nav nav-pills mb-3 mt-3 area-master-container-tab" id="pills-tab" role="tablist">
          <li className="nav-item mr-5">
            <Link className="nav-link active area-master-container-tab-heading" id="pills-home-tab" data-toggle="pill" to="/" role="tab" aria-controls="pills-home" aria-selected="true">Solar Cells</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link area-master-container-tab-heading" id="pills-profile-tab" data-toggle="pill" to="/profile" role="tab" aria-controls="pills-profile" aria-selected="false">Profile</Link>
          </li>
        </ul>
        
      </div>
    </div></section>
</div>

  )
}
