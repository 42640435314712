import React, { useEffect, useContext, useState } from 'react'
import Appbar from '../Appbar'
import './Project.css'
import 'react-circular-progressbar/dist/styles.css';
import HandleContext from "../../context/HandleContext";
import parse from 'html-react-parser'
import Cookies from 'js-cookie';
import { makeStyles } from "@material-ui/core";
import { useNavigate } from 'react-router-dom';
import InfoCard from './InfoCard';
import 'youtube-video-js';
import Navbar from '../new-pages/Navbar';

const useStyles = makeStyles(() => ({
    textField: {
        marginRight: "15px",
        background: 'white',
        backgroundColor: 'white'
    },
    input: {
        color: "grey",
        backgroundColor: 'white'
        //   padding:'10px 5px 0px 5px'
    },
    field: {
        color: 'yellow'
    },

}));


export default function DetailedProject() {
    const navigate = useNavigate();

    const [credentials, setCredentials] = useState({ no_of_cells: "1" })

    const onChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value })
    }

    const classes = useStyles();

    const context = useContext(HandleContext);
    const { ProjectDetails, details,GetData } = context;

    useEffect(() => {
        GetData('profile')
        // Cookies.get('num') ? ProjectDetails(Cookies.get('ProjectId')) : navigate('/login')
        ProjectDetails(Cookies.get('ProjectId'))

    }, [])

    let project_income = [];

    // details ? console.log(details) : console.log('no details');

    details ? details.income.forEach((e) => {
        // console.log(e)
        project_income.push(e * credentials.no_of_cells);
    }) : console.log("")

    // console.log(project_income);
    // console.log(details && details.featured_video);

    const percentage = details && details.sold_per;
    return (
        <>
            {details ? details.income.forEach((e) => {
                console.log()
            }) : console.log("nothing to print")
            }
            <Navbar />
            <div className="container" style={{ marginTop: "60px" }}>
                <div className="row">
                    <div className="col-md-7 col-sm-12">
                        <h3 style={{ fontSize: '27px', marginBottom: '40px', color: "#3b4d57" }}>{details && parse(details.project_title)}</h3>
                        <div style={{ width: '100%' }}>
                           
                            
                                    <youtube-video
                                        src={details && details.featured_video} width="640"
                                        height="360" autoplay controls />
                                </div>
                                <div className='col-md-12' style={{ textAlign: 'normal' }}>
                                    {details && parse(details.project_description)}
                                </div>

                                {/* <youtube-video width="640" height="360"
                            src="https://www.youtube.com/watch?v=NghjAvvXm9E"
                            autoplay controls /> */}

                                {/* <h4 style={{ color: 'grey', fontSize: "18px", fontWeight: '700', marginTop: '40px', textAlign: 'left' }}>About This Project</h4>
                        <p style={{ textAlign: 'normal', fontSize: '15px', color: "grey" }}>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae, voluptatum sequi, vitae, perspiciatis reprehenderit nobis porro rerum inventore necessitatibus labore minima at ipsum praesentium rem vel totam omnis consequatur? Numquam labore voluptatum, et nisi porro placeat deleniti ea assumenda sint amet velit distinctio repellendus. Similique consequatur quaerat, repudiandae totam sed illo architecto exercitationem, nam, quo soluta numquam earum temporibus veritatis sit non et necessitatibus atque dolorum obcaecati ea omnis laudantium labore. Dolore, incidunt minus. Dolor repellendus, necessitatibus perferendis iusto magnam corrupti magni, quaerat, doloremque culpa laborum at numquam accusamus fuga maxime nesciunt. Quod veritatis distinctio, quo asperiores nobis praesentium harum, amet, placeat ullam iusto vero similique suscipit aut dicta deleniti ipsa delectus voluptates eum ducimus quibusdam inventore qui esse commodi. Cum laudantium eius ipsum iste ad quidem quam, voluptatibus sint! Nihil atque provident quis assumenda quia excepturi voluptatem, quo deleniti molestiae distinctio deserunt, blanditiis eaque explicabo fuga dolorem tempore culpa voluptatibus inventore neque voluptatum exercitationem a minima sint. Aliquid excepturi consectetur, mollitia blanditiis pariatur labore? Rem amet hic pariatur numquam laudantium quas optio eveniet neque placeat quaerat ratione reiciendis delectus, perspiciatis necessitatibus asperiores vitae consequatur iure soluta qui omnis deserunt vero porro quis dolorum. Quos laborum temporibus, delectus esse reprehenderit suscipit vitae unde earum rem eligendi numquam? Illum suscipit a laudantium similique

                        </p> */}

                                {/* <div style={{ height: '50%', width: '100%' }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: "" }}
                                defaultCenter={defaultProps.center}
                                defaultZoom={defaultProps.zoom}
                            >
                                <AnyReactComponent
                                    lat={59.955413}
                                    lng={30.337844}
                                    text="My Marker"
                                />
                            </GoogleMapReact>
                        </div> */}
                        </div>

                        <div className="col-md-5 col-sm-12 col-lg-5 col-xs-12">
                            <InfoCard />

                        </div>
                    </div>
                </div>
            </>
            )

}
