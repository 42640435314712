import React, { useEffect, useContext, useState } from 'react'
import Tab from '../Tab'
import Appbar from '../Appbar'
import HandleContext from '../../context/HandleContext';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import '../Main.css'
import ClipLoader from "react-spinners/ClipLoader";


export default function Account() {
    var countDownDate = new Date().getTime();

   

    var myModal = document.getElementById("exampleModal")


    let confirm_box = document.getElementById('confirm_box');

    const context = useContext(HandleContext);
    const { Bank, GetData, userProfile ,BankUpdate} = context;

    useEffect(() => {
        GetData('profile')
        Cookies.get('banking') ? console.log() : console.log();
    }, [Cookies.get('banking')])


    const [credentials, setCredentials] = useState({ accountno: "", ifsc: "" })
    const [accountDetails, setAccountDetails] = useState({ accountno: Cookies.get('account'), ifsc: Cookies.get('ifsc'), account_holder: Cookies.get('accountHolder'), bank_name: Cookies.get('bankName'), bank_branch: Cookies.get('branch') })

    const OnChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        Cookies.set('account', credentials.accountno)
        Cookies.set('ifsc', credentials.ifsc)
        Bank(JSON.stringify(credentials), 'getbank')
    }

    const handleUpdate = async (e) => {
        e.preventDefault();
        BankUpdate(JSON.stringify(accountDetails), 'update_bank')
        window.location.reload();

    }

    return (
        <div>
            <p id="demo"></p>

            <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Please Check Your Account Details</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        {Cookies.get('banking') ?        <form onSubmit={handleUpdate}>
                            <div className="modal-body">
                                <div className="form-group modal_div">
                                    <label htmlFor="exampleInputEmail1" style={{ fontWeight: '700' }}>Account Number</label>
                                    <div style={{ fontWeight: '600' }}>{credentials.accountno} </div>
                                </div>
                                <div className="form-group modal_div">
                                    <label htmlFor="exampleInputEmail1" style={{ fontWeight: '700' }}>IFSC Code</label>
                                    <div style={{ fontWeight: '600' }}> {credentials.ifsc} </div>
                                </div>
                                <div className="form-group modal_div">
                                    <label htmlFor="exampleInputEmail1" style={{ fontWeight: '700' }}>Account Holder</label>
                                    <div style={{ fontWeight: '600' }}> {Cookies.get('accountHolder')} </div>
                                </div>
                                <div className="form-group modal_div">
                                    <label htmlFor="exampleInputEmail1" style={{ fontWeight: '700' }}>Bank Name</label>
                                    <div style={{ fontWeight: '600' }}>{Cookies.get('bankName')} </div>
                                </div>
                                <div className="form-group modal_div">
                                    <label htmlFor="exampleInputEmail1" style={{ fontWeight: '700' }}>Bank Branch</label>
                                    <div style={{ fontWeight: '600' }}>{Cookies.get('branch')} </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary" >Confirm</button>
                            </div>
                        </form> :  <ClipLoader color="white"
                size={15}
                aria-label="Loading Spinner"
                data-testid="loader"
              />}
                    </div>
                </div>
            </div>
               

            <div className='container' style={{ margin: "20px 0px" }}>

                <div>
                    <div style={{fontSize: '20px', fontWeight: '400' ,marginBottom:'20px'}}>Bank Account Details </div>
                    {/* <span style={{marginLeft:'8px',marginBottom:'30px'}} title="Lorem Ipsum is simply dummy text of the printing and typesetting industry." className='tool'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                    </span> */}
                </div>
                {userProfile && userProfile.acno ?<div style={{marginTop:'30px'}}>
                 <div className="form-group modal_div">
                    <label htmlFor="exampleInputEmail1" style={{ fontWeight: '700' }}>Account Holder</label>
                    <div style={{ fontWeight: '600' }}>{userProfile.acholder} </div>
                </div>
                 <div className="form-group modal_div">
                    <label htmlFor="exampleInputEmail1" style={{ fontWeight: '700' }}>Account Number</label>
                    <div style={{ fontWeight: '600' }}>{userProfile.acno} </div>
                </div>
                 <div className="form-group modal_div">
                    <label htmlFor="exampleInputEmail1" style={{ fontWeight: '700' }}>IFSC Code</label>
                    <div style={{ fontWeight: '600' }}>{userProfile.ifsc} </div>
                </div>
                 <div className="form-group modal_div">
                    <label htmlFor="exampleInputEmail1" style={{ fontWeight: '700' }}>Bank Name</label>
                    <div style={{ fontWeight: '600' }}>{userProfile.bank} </div>
                </div>
                 <div className="form-group modal_div">
                    <label htmlFor="exampleInputEmail1" style={{ fontWeight: '700' }}>Branch</label>
                    <div style={{ fontWeight: '600' }}>{userProfile.branch} </div>
                </div>
                </div>
                 :
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label  htmlFor="exampleInputEmail1">Enter Your Account Number</label>
                            <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="" required name='accountno' value={credentials.accountno} onChange={OnChange} />
                        </div>
                        <div className="form-group">
                            <label  htmlFor="exampleInputEmail1">Enter Your IFSC Code</label>
                            <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="" required name='ifsc' value={credentials.ifsc} onChange={OnChange} />
                        </div>

                        <button type="submit" style={{background:'#ff9c00',borderColor:'#ff9c00'}} className="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">Submit</button>
                    </form>}


            </div>

        </div>
    )
}
