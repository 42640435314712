import React from "react";
import { AppBar,  Toolbar, Box, TextField, InputLabel, Input, FormControl } from "@mui/material";
import { Link,useNavigate } from "react-router-dom";
import "./Main.css";
import { ArrowForward } from "@material-ui/icons";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import { useState, useContext,  } from "react";
import HandleContext from '../context/HandleContext'
import toastr from "toastr";
import axios from "axios";
import '../images/bc.jpg'
import Logo1 from '../images/SV_01.png'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Appbar from './Appbar'


export default function Signup() {

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
      // checkbox:""
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("First Name is Required"),
      last_name: Yup.string().required("Last Name is Required"),
      email: Yup.string().required("Email is Required"),
      password: Yup.string().required("Password is Required").min(5, 'Password must have atleast 6 digits'),
      confirm_password: Yup.string().required("Confirm Password is Required"),
      // checkbox:"Please agree terms and condition"
    }),
    onSubmit: async (values) => {
      // console.log("form submitted", values)
      await axios.post(`https://api.sunstox.com/user/register`,JSON.stringify(values),{
       headers:{'Content-Type':'application/json',
       'Client-Service':'sunstoxAPP',
       'Auth-Key':'256901zrj4UyYF2SqzoAAZpqf67',
       }
   }).then((response)=>{
                  //  console.log(response);
                   toastr.success(response.data.message)
                   navigate('/login')
               }).catch((e)=>{
                  //  console.log(e);
                   toastr.error(e.response.data.message)
               })

    }
  })

  let navigate=useNavigate()

  const context = useContext(HandleContext);
  const { SignUp ,Call} = context;

  const classes = makeStyles({
    input: {
      color: "yellow",
    },
  });

  const [credentials, setCredentials] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: ""
  });

  // console.log(credentials);

  const [values, setValues] = React.useState({

    password: "",
    confirm_password: "",
    showPassword: false,
    showconfirm_password: false,
  });


  

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleClickShowconfirm_password = () => {
    setValues({
      ...values,
      showconfirm_password: !values.showconfirm_password,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleOnChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  return (
    <div className='img'>
      <>
      <Appbar/>
      {/* <nav className="navbar navbar-expand-lg navbar-* bg-*" style={{
                background: "rgba(255, 255, 255, 0.5)",
                border: "1px solid #ddd",
                boxShadow: "0px 2px 5px #ddd",
                top: 0,
                left: 0,
                margin: 0,
                position: "static",
            }}>
                <a className="navbar-brand" href="#">
                    <img src={Logo1} width="150" height="50" className="d-inline-block align-top" alt="" />

                </a>
              
              
            </nav> */}
      <div>
      <div className="container ">
        <div className="row">
          <div className="col-md-12">
            <div
              className="card"
              id="cards"
              style={{ display: "block", boxShadow: "0px 2px 5px #ddd" }}
            >
              <div
                className="card-header"
                style={{
                  // backgroundColor: "rgb(162 179 207)",
                  backgroundColor: "#ff9c00",
                  color: "white",
                  fontWeight: "600",
                }}
              >
                <h3>Welcome, sign up</h3>
              </div>
              
              <div className="card-body" id="signupbody" >
                <form onSubmit={formik.handleSubmit}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px", }}>
                  <TextField
                    required
                    className={classes.input}
                    id="standard-required"
                    label="First Name"
                    name="first_name"
                    variant="standard"  
                    sx={{ width: "45%" }}
                    onChange={formik.handleChange}
                    error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                    helperText={formik.touched.first_name && formik.errors.first_name}
                    onBlur={formik.handleBlur}
                  />
                  <TextField
                    required
                    id="standard-required"
                    label="Last Name"
                    name="last_name"
                    variant="standard"
                    // value={credentials.last_name}
                    sx={{ width: "45%" }}
                    // onChange={handleOnChange}
                    onChange={formik.handleChange}
                    error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                    helperText={formik.touched.last_name && formik.errors.last_name}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <TextField
                  required
                  id="standard-required"
                  label="Email Address"
                  name="email"
                  variant="standard"
                  // value={credentials.email}
                  sx={{ width: "100%" }}
                  // onChange={handleOnChange}
                  onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    onBlur={formik.handleBlur}
                />
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px ", marginBottom: "40px ", }} >
                  <FormControl sx={{ width: "50%" }} variant="standard" required>
                   
                    <TextField
                      id="standard-adornment-password"
                      label="Password"
                      variant="standard"
                      type={values.showPassword ? "text" : "password"}
                      name="password"
                      onChange={formik.handleChange}
                      error={formik.touched.password && Boolean(formik.errors.password)}
                      helperText={formik.touched.password && formik.errors.password}
                      onBlur={formik.handleBlur}
                 
                      InputProps={{ // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {values.showPassword? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </FormControl>
                  <FormControl sx={{ width: "45%" }} variant="standard" required>
                 
                    <TextField
                      id="standard-adornment-confirm_password"
                      variant="standard"
                      label="Confirm Password"
                      type={values.showconfirm_password ? "text" : "password"}
                      name="confirm_password"
                   
                      onChange={formik.handleChange}
                      error={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
                      helperText={formik.touched.confirm_password && formik.errors.confirm_password}
                      onBlur={formik.handleBlur}
                      InputProps={{ // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowconfirm_password}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {values.showconfirm_password? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </FormControl>
                </div>
                <div>
                <input style={{height:'14px'}} type="checkbox" aria-label="Checkbox for following text input" required/><span style={{fontSize:'14px',marginLeft:'8px',}}>By creating an account, you agree to <a href="https://sunstox.com/terms-of-service/" target='_blank'>Conditions of Use</a> and <a href="https://sunstox.com/privacy-policy/" target='_blank'>Privacy Policy.</a></span>
                </div>
                <div style={{ marginBottom: "20px", display: "flex" ,marginTop:'15px'}}>
                  {" "}
                  <Link to="/login" style={{ color: "#ff9c00" }}>
                    Already have an account? Click here to login{" "}
                  </Link>
                </div>
                <button type="submit"  className="signup btn " style={{border: "2px solid #ff9c00",borderRadius: "12px",display:'flex',justifyContent:'center',padding: "7px 30px",color: "white",fontWeight: "700",boxShadow: "0px 2px 5px #ddd",outline: "none",backgroundColor: "#ff9c00", margin:'auto'}}>
                  <div style={{ textDecoration: "none", color: "white" }}>
                    <span style={{ display: "flex" }}>Sign Up<span style={{ marginLeft: "12px" }}><ArrowForward /> </span>
                    </span>
                  </div>
                </button>
              </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
    </div>
  );
}
