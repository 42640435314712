import React, { useEffect, useContext, useState } from 'react'
import Appbar from '../Appbar'
import './Project.css'
import 'react-circular-progressbar/dist/styles.css';
import HandleContext from "../../context/HandleContext";
import parse from 'html-react-parser'
import Cookies from 'js-cookie';
import { makeStyles} from "@material-ui/core";
import { useNavigate } from 'react-router-dom';
import InfoCard from './InfoCard';



const useStyles = makeStyles(() => ({
    textField: {
        marginRight: "15px",
        background: 'white',
        backgroundColor: 'white'
    },
    input: {
        color: "grey",
        backgroundColor: 'white'
        //   padding:'10px 5px 0px 5px'
    },
    field: {
        color: 'yellow'
    },

}));


export default function DetailedProject() {
    const navigate=useNavigate();

    const [credentials, setCredentials] = useState({ no_of_cells: "1" })

    const onChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value })
    }

    const classes = useStyles();

    const context = useContext(HandleContext);
    const { ProjectDetails, details } = context;

    useEffect(() => {
        console.log('Detail project page');
            Cookies.get('num') ? ProjectDetails(Cookies.get('ProjectId')):navigate('/login')
            console.log('called');
    }, [])

    let project_income=[];

    details ? console.log(details) : console.log('no details');

    details ? details.income.forEach((e)=>{
        console.log(e)
        project_income.push(e*credentials.no_of_cells);
    }):console.log("nothing to print")

console.log(project_income);
    
    
    const percentage = details && details.sold_per;
    console.log(details && details.featured_video);
    return (
        <>
        {  details ? details.income.forEach((e)=>{
        console.log(e)
    }):console.log("nothing to print")
}
            <Appbar />
            <div className="container" style={{ marginTop: "60px" }}>
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div style={{  fontSize: '25px',fontWeight:'500', marginBottom: '40px', color: "#3b4d57" }}>{details && parse(details.project_title)}</div>
                      
                            jgiuiknknslnlsbbddf
                        <div className='col-md-12' style={{ textAlign: 'normal' }}>
                            {details && parse(details.project_description)}
                        </div>
                    <InfoCard/>
                    </div>
                    {/* <div className="col-md-5 col-sm-12 col-lg-5 col-xs-12">
                        <InfoCard/>
                       
                    </div> */}
                </div>
            </div>
        </>
    )

}
