import React, { useState, useContext } from 'react'
import { AppBar, Toolbar, Box, TextField, } from "@mui/material";
import { Link } from "react-router-dom";
import HandleContext from '../context/HandleContext'
import { Telegram } from '@material-ui/icons';
import Logo1 from '../images/SV_01.png'
import Appbar from './Appbar'

export default function Activation() {
    const context = useContext(HandleContext);
    const { ForgotPassword, Call } = context;
  
    const [credentials, setCredentials] = useState({
      username: "",
    });
    const handleOnChange = (e) => {
      setCredentials({ ...credentials, [e.target.name]: e.target.value });
    };
  
    const handleSubmit = () => {
      // ForgotPassword(JSON.stringify(credentials));
      console.log('clickrd');
      Call(JSON.stringify(credentials), 'resendactivation')
    }
  return (
    <div className='img'> 
    <Appbar/>
     {/* <nav className="navbar navbar-expand-lg navbar-* bg-*" style={{
                background: "rgba(255, 255, 255, 0.5)",
                border: "1px solid #ddd",
                boxShadow: "0px 2px 5px #ddd",
                top: 0,
                left: 0,
                margin: 0,
                position: "static",
            }}>
                <a className="navbar-brand" href="#">
                    <img src={Logo1} width="150" height="50" className="d-inline-block align-top" alt="" />

                </a>
              
              
            </nav> */}
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="card" id="cards" style={{ display: "block", boxShadow: "0px 2px 5px #ddd" }}>
              <div className="card-header" style={{ backgroundColor: "#ff9c00", color: "white", fontWeight: "600", }} >
                <h3>Get Activation Link</h3>
              </div>
              <div className="card-body">
                <div className="elements">
                  <div style={{ color: 'grey', fontSize: '18px', textAlign: 'initial' }}>Please provide your email address and click the button below to activate your account. </div>
                  <TextField
                    required
                    id="standard"
                    label="Email Address"
                    name="username"
                    variant="standard"
                    value={credentials.username}
                    sx={{ width: "100%", marginTop: '20px' }}
                    onChange={handleOnChange}
                  />
                  <div style={{ marginBottom: "10px",marginTop:'20px', display: 'flex', flexDirection: 'column', textAlign: 'initial' }}>

                  <Link to="/login" style={{ color: "#ff9c00", width: 'fit-content', marginBottom: '10px' }}>Login to your account</Link>
                    {/* <Link to="/signup" style={{ color: "#ff9c00" }}>
                      Need An Account ?
                    </Link>
                    
                    <Link to="/login" style={{ color: "#ff9c00" }}>
                      Login To Your Account
                    </Link> */}

                  </div>
              
                  <button type="submit" onClick={handleSubmit} className="Submit " style={{ border: "2px solid #ff9c00", borderRadius: "12px", padding: "7px 30px", color: "white", fontWeight: "700", boxShadow: "0px 2px 5px #ddd", outline: "none", backgroundColor: "#ff9c00", marginTop: '40px',display:'flex',justifyContent:'center',margin:'auto' }}>
                    <Link to="" style={{ textDecoration: "none", color: "white" , margin:'auto'}}>
                      <span style={{ display: "flex" }}>Send
                        <span style={{ marginLeft: "10px" }}><Telegram /> </span>
                      </span>
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div></div>
  )
}
