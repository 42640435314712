import React from 'react'
import Appbar from '../Appbar'
import { useContext, useEffect } from 'react';
import HandleContext from '../../context/HandleContext';


export default function Payment_History() {
    
    let count=1;

    let context=useContext(HandleContext)
    const { PaymentHistory, paymentHistory} = context;
    useEffect(() => {
        PaymentHistory("payment_history")
    }, [])

// paymentHistory ? console.log(paymentHistory) : console.log('nothing to paint');


    return (
        <>
            <Appbar />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card" style={{marginTop:'20px'}}>
                            <div className="card-header card-secondary" style={{color:'grey'}}>Payment History</div>
                            <div className="card-body">
                            <table className="table table-striped table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">S. No.</th>
                                        <th scope="col">Request Date</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Remarks</th>
                                        <th scope="col">Status</th>
                                 
                                    </tr>
                                </thead>
                                <tbody>
                                    {paymentHistory && paymentHistory.map((e)=>{
                                        // console.log(e);
                                        return <tr key={e.id}>
                                         <th scope="row">{count++}</th>
                                         <td>{e.request_date}</td>
                                         <td>{e.amount}</td>
                                         <td>{e.remarks}</td>
                                         <td>{e.st}</td>
                                     </tr>
                                    })}
                                   
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
