import React, { useEffect, useContext, useState } from 'react'
import Tab from '../Tab'
import Appbar from '../Appbar'
import HandleContext from '../../context/HandleContext';
import Cookies from 'js-cookie';
import { LocalDining } from '@material-ui/icons';
import ClipLoader from "react-spinners/ClipLoader";
import Countdown from 'react-countdown';



export default function AadharVerification() {

  const [loading, setLoading] = useState(false)

  const context = useContext(HandleContext);
  const { Aadhaar, AadhaarVerification, status,GetData } = context;



  const [otpValue, setOtpValue] = useState({ aadhaar: Cookies.get('adnum'), ref_id: Cookies.get('ref_id'), otp: "" })

  let verifyBtn = document.getElementById('verifyBtn')

  useEffect(() => {

    Cookies.remove('status')
    GetData('profile')
  }, AadhaarVerification)

  setTimeout(() => {
    setLoading(false)
  }, 6000);

  const handleChange = (e) => {
    setOtpValue({ ...otpValue, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    AadhaarVerification(JSON.stringify(otpValue), 'update_aadhaar')
  }

  let inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {

        elmnt.target.form.elements[next].focus()
      }
    }
    else {
      console.log("next");

      const next = elmnt.target.tabIndex;
      if (next <= 6) {
        elmnt.target.form.elements[next].focus()
      }
    }

  }
  // const Completionist = () => <span>You are good to go!</span>;
  const Completionist = () => <div onClick={() => {
    Aadhaar(JSON.stringify({ aadhaar: Cookies.get('adnum') }), "aadhaarotp")
  }} style={{ fontWeight: '500', color: 'royalblue', fontStyle: 'italic', cursor: "pointer" }}>Resend OTP</div>;

  return (
    <div>
      <Appbar />
      {/* <Tab /> */}
      {/* {status ? */}
      {/* <Countdown date={Date.now() + 60000}>
        <Completionist />
      </Countdown> */}
      <div className='container' style={{ marginTop: '30px' }}>
        <h2>Verify, it's you</h2>
        <div style={{ margin: '17px 0px 28px 0px', fontWeight: '500' }}>Aadhaar OTP request sent to UIDAI. You will be recieving OTP from UIDAI shortly to the number registered with Aadhaar.</div>
        <h5 style={{ marginBottom: '20px', fontWeight: '400' }} >Enter the OTP <span style={{ color: 'red' }}>**</span></h5>


        <form onSubmit={handleSubmit}>
          <div className="otpContainer">

            <input
              name="otp"
              type="text"
              autoComplete="off"
              className="otpInput"
              value={otpValue.otp}
              // onKeyPress={keyPressed}
              onChange={handleChange}
              tabIndex="1" maxLength="6"
              // onKeyUp={e => inputfocus(e)}
              required
            />
            <div style={{ display: 'flex', marginTop: '15px', }}>
              <div style={{ fontSize: '16px', fontWeight: '500', color: 'grey', marginRight: '60px' }}><span>{<Countdown date={Date.now() + 59000}>
                <Completionist />
              </Countdown>}</span></div>
              {/* <div onClick={() => {
                Aadhaar(JSON.stringify({ aadhaar: Cookies.get('adnum') }), "aadhaarotp")
              }} style={{ fontWeight: '500', color: 'royalblue', fontStyle: 'italic', cursor: "pointer" }}>Resend OTP</div> */}
            </div>

          </div>
          <button className="btn btn-primary" id='verifyBtn' type="submit"> {loading === true ?
            <ClipLoader
              color="white"
              size={15}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            : 'Submit'}</button>
        </form>
      </div>
      {/* : <div>Loading...</div>} */}
    </div>
  )
}
