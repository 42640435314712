import React from 'react'
import Navbar from './Navbar'
import $ from 'jquery'
import  { useEffect, useContext, useState } from 'react'
import HandleContext from "../../context/HandleContext";
// import "./Main.css";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Aadhar from '../Projects/Aadhar';
import Account from '../Projects/Account';
import Pancard from '../Projects/Pancard';

export default function Profile() {

  const handleOnChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
};

const handleSubmit = async (e) => {
    e.preventDefault();    
    Call(JSON.stringify(credentials), 'update_profile')
    setTimeout(() => {
        GetData("profile");
    }, 200);
}

  let navigate = useNavigate();
  const context = useContext(HandleContext);
  const { GetData, userProfile, Call ,profile,UserDashboard,userInvestment} = context;

  useEffect(() => {
      GetData('profile')
      Cookies.get('num') ? UserDashboard("dashboard") : navigate('/login')
      Cookies.get('num') ? GetData("profile") : navigate('/login')
  }, []);

 

    let [credentials, setCredentials] = useState({
      first_name:Cookies.get('first_name'),
      last_name: Cookies.get('last_name'),
      phone_no: Cookies.get('phone_no'),
      address: Cookies.get('address'),
      city: Cookies.get('city'),
      state: Cookies.get('state'),
      country: Cookies.get('country'),
      pincode: Cookies.get('pincode'),
      gst_number: Cookies.get('gst_number'),
      is_company: Cookies.get('is_company')
  });

    
    // console.log(userProfile);
    return (
    <div>
        <Navbar/>
  <section>
    <div className="container-fluid mt-3">
      <div className="row ">
        <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
          <div className="card profile-card">
            <div className="card-header text-center profile-card-header">
              <h5>{userProfile && userProfile.first_name } {userProfile && userProfile.last_name }</h5>
              <p>{userProfile && userProfile.email_id }</p>
            </div>
            <div className="card-body profile-card-body">
              <div className="row">
                <div className="col-10">
                  <h5 className="m-0"><i className="feather icon-arrow-up text-c-green" /> Investment</h5>
                </div>
                <div className="col text-right">
                  <span className="d-block">{userInvestment && userInvestment.total_investment}</span>
                </div>
              </div>
            </div>
            <div className="card-body profile-card-body">
              <div className="row">
                <div className="col-10">
                  <h5 className="m-0"><i className="feather icon-arrow-up text-c-green" /> Cellbought</h5>
                </div>
                <div className="col text-right">
                  <span className="d-block">{userInvestment && userInvestment.total_cells_bought}</span>
                </div>
              </div>
            </div>
            <div className="card-body profile-card-body">
              <div className="row">
                <div className="col-10">
                  <h5 className="m-0"><i className="feather icon-arrow-up text-c-green" /> Payout Inprocess</h5>
                </div>
                <div className="col text-right">
                  <span className="d-block">{userInvestment && userInvestment.payout_inprocess}</span>
                </div>
              </div>
            </div>
            <div className="card-body profile-card-body">
              <div className="row">
                <div className="col-10">
                  <h5 className="m-0"><i className="feather icon-arrow-up text-c-green" />Payout Recieved</h5>
                </div>
                <div className="col text-right">
                  <span className="d-block"> {userInvestment && userInvestment.payout_received}</span>
                </div>
              </div>
            </div>
            <div className="card-header" style={{height: '11rem', backgroundColor: 'white'}}>
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12">
          <div className="card profile-main-card">
            <div className="card-header profile-card-header">
              <h6>Personal Details</h6>
              <p><i className="fas fa-info-circle" /> &nbsp; Please fill your Details below</p>
            </div>
            <div className="card-body">
              <form className="profile-form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-xl-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">First Name</label>
                      <input type="text" className="form-control" name='first_name' value={credentials.first_name} required onChange={handleOnChange}/>
                    </div>
                  </div>
                  <div className="col-xl-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Last Name</label>
                      <input type="text" className="form-control" name='last_name' value={credentials.last_name} required onChange={handleOnChange} />
                    </div>
                  </div>
                  <div className="col-xl-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Address</label>
                      <input type="text" className="form-control" name='address' value={credentials.address} onChange={handleOnChange} />
                    </div>
                  </div>
                  <div className="col-xl-6 col-md-6 col-sm-12">
                  <div className="form-group">
                      <label className="form-label">State</label>
                      <input type="text" className="form-control" name='state' value={credentials.state} onChange={handleOnChange}  />
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-4 col-sm-12">
                  <div className="form-group">
                      <label className="form-label">Country</label>
                      <input type="text" className="form-control" name='country' value={credentials.country} onChange={handleOnChange} />
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-4 col-sm-12">
                  <div className="form-group">
                      <label className="form-label">City</label>
                      <input type="text" className="form-control" name='city' value={credentials.city} onChange={handleOnChange}  />
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-4 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Pin Code</label>
                      <input type="text" className="form-control" name='pincode' value={credentials.pincode} onChange={handleOnChange} />
                    </div>
                  </div>
                  <div className="col-xl-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Phone</label>
                      <input type="text" className="form-control" name='phone_no' value={credentials.phone_no} required onChange={handleOnChange} />
                    </div>
                  </div>
                  <div className="col-xl-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">GST No</label>
                      <input type="text" className="form-control" name='gst_number' value={credentials.gst_number} onChange={handleOnChange} />
                    </div>
                  </div>
                  {/* <div className="col-xl-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Are you Registering as a Company ?</label>
                      <div className="d-flex">
                        <div className="form-check">
                          <label className="form-check-label"> <input className="form-check-input" type="radio"  value="1" name="is_company" /> Yes </label>
                        </div>
                        &nbsp; &nbsp; &nbsp;
                        <div className="form-check">
                          <label className="form-check-label"> <input className="form-check-input" type="radio" value="0" name="is_company" /> No </label>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="text-right">
                  <button type="submit" className="btn profile-card-btn mr-3">Add</button>
                </div>
              </form>
            </div>
          </div>
          <div className='row'>
          <div className='col-md-6'  style={{ background: ' #ffffffeb', border: '2px solid #b7b6b647', margin: '10px 0px', borderRadius: '10px' }}>
          <Aadhar/>
          <Pancard/>
          </div>
          <div className='col-md-6' style={{ background: ' #ffffffeb', border: '2px solid #b7b6b647', margin: '10px 0px', borderRadius: '10px' }}>
        <Account/>
        </div>
        </div>
        </div>
      </div>
    </div>
  </section>
</div>

    )
}
