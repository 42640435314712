import React, { useEffect, useContext } from 'react'
// import Appbar from '../Appbar'
import Navbar from '../new-pages/Navbar';
import { Link, useNavigate} from 'react-router-dom'
import img1 from '../../images/1920.jpg'
import HandleContext from "../../context/HandleContext";
import parse from 'html-react-parser'
import Cookies from 'js-cookie';
import { AppBar, Button, Toolbar, Box} from '@mui/material';
import Logo1 from '../../images/SV_01.png'



export default function Solar() {

  const context = useContext(HandleContext);
  const { Projects, project ,ProjectDetails} = context;

  const navigate=useNavigate();

  useEffect(() => {
    
  Projects('ongoing_projects')

  }, [])

  // project ? console.log(project) : console.log('nothing');

  return (
    <>
    <Navbar/>
    
      <div className="head" style={{color:'#3b4d57', fontWeight: '500', textAlign: 'center', margin: '40px 0px', fontSize: '30px' }}>Current Investment Opportunities</div>
      
      <div className="container">
        <div className="row" >
          {project ? project.map((e) => {
         
            return <div key={e.id} className="col-md-4 col-sm-12" style={{ marginBottom: '50px' }}>
              <div className="card" id='solarcards' style={{boxShadow: '0 3px 12px -1px rgb(7 10 25 / 20%), 0 22px 27px -20px rgb(7 10 25 / 20' }}>
                <div className='card-header' style={{backgroundColor:'#233357',padding: '11px 14px 2px 10px'}}>
                  <h5 style={{fontSize:'16px',color:'white' }} className='card-title'> {e.current_status}</h5>
                </div>
                  <img className="card-img-top" style={{cursor:'pointer',height:'300px'}} src={e.featured_image} alt="Card image cap" onClick={()=>{
                  //  if(Cookies.get('num')){
                    ProjectDetails(e.id)
                    navigate('/details')
                    Cookies.set('ProjectId',e.id)
                //  }else
                //   {
                //     navigate('/login')
                // }
                    }}/>
               
                <div className="card-body" style={{ textAlign: 'initial' }}>
                  <h5 className="card-title" style={{fontSize:'15px'}}><div onClick={()=>{
              //  if(Cookies.get('num')){
                  ProjectDetails(e.id)
                  navigate('/details')
                  Cookies.set('ProjectId',e.id)
              //  }else
              //   {
              //     navigate('/login')
              // }

                  }} 
                  style={{ color:'#3b4d57', textDecoration: 'none',cursor:'pointer' }}>
                    {e.project_title}
                  </div></h5>
                  {/* <p className="card-text" style={{ fontSize: '15px', color: 'grey',fontSize:'14px' }}>{parse(e.short_description)}
                  </p> */}
                </div>
                <div className="footer" style={{background:'white',margin:'5px 0px 20px 0px'}}>
                  <div style={{display:'flex',justifyContent:'space-around'}}>
                  <div className="project_investment">
                  <div className='investment_data'>{e.investors}</div>
                  <div className='investors'>Investors</div>
                  </div>
                  <div className="project_investment">
                  <div className='investment_data'>{e.investment}</div>
                  <div className='investors'>Investment</div>
                  </div>
                  <div className="project_investment">
                  <div className='investment_data'>{e.available_cells}</div>
                  <div className='investors'>Available Cells</div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          }) : <div>${'No Projects'}</div>}


        
        </div>
      </div>

    </>
  )
}
