import React, { useEffect, useContext, useState } from 'react'
import Tab from '../Tab'
import Appbar from '../Appbar'
// import HandleContext from "./context/HandleContext";
import HandleContext from '../../context/HandleContext';
import Userprofile from '../Tab';

export default function Pancard() {
  const context = useContext(HandleContext);
  const { Call,GetData,userProfile } = context;

  useEffect(() => {
    GetData("profile")
  }, [userProfile])
  

  const [credentials, setCredentials] = useState({pan_no:""})

  const OnChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
};

const handleSubmit = async (e) => {
    e.preventDefault();
    Call(JSON.stringify(credentials), 'update_pan')
}

  return (
    <div>
  
     <div className='container' style={{margin:"10px 0px"}}>
     {userProfile && userProfile.pan ?
   <div className="form-group modal_div">
   <label htmlFor="exampleInputEmail1" style={{ fontWeight: '700' }}>Pancard Number</label>
   <div style={{ fontWeight: '600' }}>{userProfile.pan} </div>
</div>:
  <form onSubmit={handleSubmit}>
    <div className="form-group"> 
      <label  htmlFor="exampleInputEmail1">Enter Your Pan Number</label>
      <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="" required name='pan_no' value={credentials.pan_no} onChange={OnChange}/>
    </div>
  
    <button type="submit" className="btn btn-primary" style={{background:'#ff9c00',borderColor:'#ff9c00'}}>Submit</button>
  </form>}
</div>

    </div>
  )
}
