import React from 'react'
import Navbar from './Navbar'
import { useState, useContext, useEffect, } from "react";
import HandleContext from '../../context/HandleContext'
import { Link } from 'react-router-dom';
import { FacebookShareButton, FacebookIcon } from 'react-share';
import { TwitterShareButton, TwitterIcon } from 'react-share';
import { LinkedinShareButton, LinkedinIcon } from 'react-share';
import { WhatsappShareButton, WhatsappIcon } from 'react-share';
// import { MailruShareButton, MailruIcon } from 'react-share';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

export default function Setting() {

  let navigate=useNavigate()

  const context = useContext(HandleContext);
  const { ChangePassword, Call } = context;

  const [credentials, setCredentials] = useState({ old_password: "", password: "", confirm_password: "" });

  useEffect(() => {
    Cookies.get('num') ? console.log() : navigate('/login')
}, []);
  const handleOnChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    // ChangePassword(JSON.stringify(credentials));
    Call(JSON.stringify(credentials), 'change_password')
  }
  return (
    <div>
      <Navbar />
      <section>
        <div className="container-fluid impression-dashboard-main-card mt-3">
          <div className="row">
            <div className="col-xl-4 col-md-4 col-sm-12">
              <div className="card comp-card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col">
                      {/* Trigger the modal with a button */}
                      <button style={{ border: 'none' }} type="button" className="change-password-modal-btn btn-lg" data-toggle="modal" data-target="#myModal">
                        <h5><i className="fas fa-user-clock" /> &nbsp; Change Password</h5>
                      </button>
                      {/* Modal */}
                      <div className="modal fade" id="myModal" role="dialog">
                        <div className="modal-dialog">
                          {/* Modal content*/}
                          <div className="modal-content">
                            <div className="modal-header change-password-modal-header">
                              <h5 className="modal-title">Change your Password</h5>
                              <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>
                            <form onSubmit={handleSubmit}>
                              <div className="modal-body">
                                <div className="form-group">
                                  <label className="form-label">Current Password</label>
                                  <input type="password" className="form-control" name="old_password" placeholder="************" onChange={handleOnChange} value={credentials.old_password} required />
                                </div>
                                <div className="form-group">
                                  <label className="form-label">New Password</label>
                                  <input type="password" className="form-control" name="password" placeholder="************" onChange={handleOnChange} value={credentials.password} required />
                                </div>
                                <div className="form-group">
                                  <label className="form-label">Confirm Password</label>
                                  <input type="password" className="form-control" name="confirm_password" placeholder="************" onChange={handleOnChange} value={credentials.confirm_password} required />
                                </div>

                              </div>
                              <div className="modal-footer">
                                <div className="text-right">
                                  <button type="submit" className="btn profile-card-btn mr-3">Save</button>
                                  <button type="button" className="btn profile-card-btn" data-dismiss='modal'>Cancel</button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-4 col-sm-12">
              <div className="card comp-card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col">
                      <h5 className="m-b-20  color-black list-type"><i className="fas fa-id-card-alt" /> &nbsp; <a href="https://sunstox.com/contact-us/" style={{ textDecoration: 'none', color: 'black' }}>Contact us</a></h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-12">
              <div className="card comp-card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col">
                      <h5 ><i className="fas fa-question" /> &nbsp;<a style={{ color: 'black', textDecoration: 'none' }} target='bl
                    ' href='https://sunstox.com/faqs/'> FAQ</a></h5>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-12">
              <div className="card comp-card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col">
                      <h5 className="m-b-20"><i className="fas fa-hands-helping" /> &nbsp;
                        <a style={{ color: 'black', textDecoration: 'none' }} href='https://sunstox.com/contact-us/' target='_blank'> Help</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-12">
              <div className="card comp-card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col">
                      <a style={{ textDecoration: 'none' }} target='_blank' href="https://sunstox.com/about/" > <h5 className="m-b-20  color-black list-type"><i className="fas fa-address-card" /> &nbsp; About us</h5></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-12">
              <div className="card comp-card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col">
                      <button style={{ border: 'none' }} type="button" className="change-password-modal-btn btn-xl" data-toggle="modal" data-target="#myModal3">
                        <h5><i className="fas fa-hand-holding" /> &nbsp; Invite</h5>
                      </button>
                      {/* Modal */}
                      <div className="modal fade" id="myModal3" role="dialog">
                        <div className="modal-dialog">
                          {/* Modal content*/}
                          <div className="modal-content">
                            <div className="modal-header change-password-modal-header">
                              <h5 className="modal-title">Invite</h5>
                              <button type="button" className="close" data-dismiss="modal">×</button>
                            </div>
                            <div className="modal-body">
                              {/* <div class="notifican-list-detail notifiaction-modal-heading">
                                                          <p><b>View all Notification</b></p>
                                                      </div> */}
                                <div className="row col-12">
                                <div className="col-md-3 col-sm-3">

                              
                                   <a href='https://www.facebook.com/sharer/sharer.php?u=https://sunstox.com/'>   <FacebookIcon size={32} round /> </a>
                                </div>

                                <div className="col-md-3 col-sm-3">
                               
                                 <a href='https://twitter.com/intent/tweet?url=https://sunstox.com/&text=Sunstox enables individuals to finance the green infastructure and earn money!'>   <TwitterIcon size={32} round /> </a>
                                </div>
                                <div className="col-md-3 col-sm-3">
                                 
                                <a href='https://www.linkedin.com/shareArticle?mini=true&url=https://sunstox.com/&title=&summary=Sunstox enables individuals to finance the green infastructure and earn money!&source='>   <LinkedinIcon size={32} round /> </a> 
                                </div>
                       
                                <div className="col-md-3 col-sm-3">
                                  {/* <WhatsappShareButton
                                    url={'https://sunstox.com/'}
                                    quote={'Sunstox enables individuals to finance the green infastructure and earn money!'}
                                    hashtag="#renewablenergy"
                                  > */}
                              <a href='https://api.whatsapp.com/send?text=https://sunstox.com/'>      <WhatsappIcon size={32} round /></a>
                                  {/* </WhatsappShareButton> */}
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer invite-modal-footer">
                           
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-12">
              <div className="card comp-card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col">
                      <h5 style={{ cursor: 'pointer' }} className="m-b-20"><i className="fas fa-shield-alt" style={{ cursor: 'pointer' }} /> &nbsp;
                        <a style={{ color: 'black', textDecoration: 'none', cursor: 'pointer' }} href='https://sunstox.com/privacy-policy/' target='_blank'>Privacy Policy</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  )
}
