import React from 'react'
import Logo1 from '../images/SV_01.png'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom';

export default function Navbar() {

    const navigate = useNavigate();

    const logOut = () => {
        Cookies.remove('num')
        Cookies.remove('id')
        navigate('/login')
    }

    const login = () => {
        navigate('/login')
    }
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-white bg-white" style={{
                background: "rgba(255, 255, 255, 0.5)",
                border: "1px solid #ddd",
                boxShadow: "0px 2px 5px #ddd",
                top: 0,
                left: 0,
                margin: 0,
                position: "static",
            }}>
                <a className="navbar-brand logo" href="#">
                    <img src={Logo1} width="150" height="50" className="d-inline-block align-top" alt="" style={{margin:'0px'}} />
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon">
                        <i className="fa-solid fa-bars"></i>
                    </span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div className="navbar-nav" >
                        <a className="nav-item nav-link mainLinks" style={{ marginRight: '15px' }} href="https://sunstox.com/">Home </a>
                        <a className="nav-item nav-link mainLinks" style={{ marginRight: '15px' }} href="https://sunstox.com/how-it-works/">How It Works </a>
                        <div className="dropdown nav-item nav-link" style={{ marginRight: '15px' }}>
                            <div className="dropbtn mainLinks ">Projects</div>
                            <div className="dropdown-content">
                                <a href="https://login.sunstox.com/">Our Projects</a>
                                <hr style={{ margin: 0 }} />
                                <a href="https://sunstox.com/">Request For Finance</a>
                            </div>
                        </div>
                        <a className="nav-item nav-link mainLinks" style={{ marginRight: '15px' }} href="https://sunstox.com/about/">About Us </a>
                        <div className="dropdown nav-item nav-link" style={{ marginRight: '15px' }}>
                            <div className="dropbtn mainLinks">For Bussiness</div>
                            <div className="dropdown-content">
                                <a href="https://sunstox.com/">Boost your Impact</a>
                                <hr style={{ margin: 0 }} />
                                <a href="https://sunstox.com/">Make A Difference</a>
                            </div>
                        </div>
                        {/* {Cookies.get('num') ? <>
                            <div className="dropdown nav-item nav-link">
                                <div className="dropbtn mainLinks">My Dashboard</div>
                                <div className="dropdown-content">
                                    <Link to="/user-dashboard">User Dashboard</Link>
                                    <hr style={{ margin: 0 }} />
                                    <Link to="/update">My Profile</Link>
                                    <hr style={{ margin: 0 }} />
                                    <Link to="/changepassword">Change Password</Link>
                                </div>
                            </div>

                        </> : ''} */}

                    </div>
                </div>
                        <div style={{Left:'auto'}} >
                        {Cookies.get('num') ? <>
                            <div className="dropdown nav-item nav-link " style={{ outline: 'none' }}>
                                <a className='dropbtn mainLinks' onClick={logOut} >Log Out </a>
                            </div>
                        </> :
                            <div style={{marginLeft:'auto'}}>
                                <div className="dropdown nav-item nav-link " >
                                    <a className='dropbtn mainLinks loginbtn' onClick={login} >Login </a>
                                </div>
                                <div className="dropdown nav-item nav-link " >
                                    <a className='dropbtn mainLinks lbtns' onClick={() => {
                                        navigate('/signup')
                                    }} >Sign Up </a>
                                </div>
                            </div>
                        }
                        </div>
            </nav>
        </div>
    )
}
