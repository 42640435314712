import React from 'react'
import Navbar from './Navbar'

export default function Aboutus() {
  return (
   <div>
    <Navbar/>
  <section>
    <div className="container-fluid impression-dashboard-main-card mt-3">
      {/* Main content starts here*/}
      {/*video sec starts here*/}
      {/* <div class="embed-responsive embed-responsive-16by9">
              <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/tgbNymZ7vqY"></iframe>
              </div> */}
      <video controls style={{width: '100%'}}>
        <iframe width={560} height={315} src="https://www.youtube.com/embed/8bL7JVgp3ws?start=8" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
      </video>
      {/*video sec ends here*/}
      {/*we are sunstox sec starts here*/}
      <div className="container-fluid mt-4 aboutus-sunstox-content">
        <h5 className="color-theme ">Today’s savings, Tomorrow’s Benefit</h5>
        <div>
          <h6>We are &nbsp; <span className="color-theme">Sunstox</span></h6>
          <p>
            a peer-to-peer solar leasing platform that allows individual and companies anywhere in India to directly invest in revenue-generating solar assets and earn steady returns. We are at the forefront of championing
            financial freedom for individuals and businesses by making it possible for them to break free from paying costly electricity bills.
          </p>
          <p>
            Our highly engaging and transparent financial marketplace paves way for anyone in India to invest in profitable solar projects with confidence, by providing ultimate transparency on investment performance through
            real-time revenue distribution.
          </p>
          <p>
            Our focus is to build an environmentally conscious community that significantly contributes to India’s drive for Sustainable Energy Independence and Carbon Emission Reduction. We aim to achieve this by creating
            new opportunities, for more people to invest in dependable and inexhaustible renewable energy which guarantees solid profits.
          </p>
        </div>
      </div>
      {/*we are sunstox sec ends here*/}
      {/*vision,mission sec starts here*/}
      <div className="container-fluid vission-mission-sec mt-5 card-group">
        <div className="row" style={{width: '100%'}}>
          <div className="col-xl-6 col-md-6 col-sm-12">
            <div className="card mb-3 vission-mission-card d-flex h-100" style={{maxWidth: 540}}>
              <div className="row g-0">
                <div className="col-xl-3 col-md-3 col-sm-3">
                  <i className="fas fa-binoculars vision-mission-icon" />
                </div>
                <div className="col-xl-9 col-md-9 col-sm-9">
                  <div className="card-body">
                    <h5 className="card-title color-theme">Our Vision</h5>
                    <p className="card-text">A world powered by distributed renewable energy, financed by decentralized money.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-md-6 col-sm-12">
            <div className="card mb-3 vission-mission-card d-flex h-100" style={{maxWidth: 540}}>
              <div className="row g-0">
                <div className="col-xl-3 col-md-3 col-sm-3">
                  <i className="fas fa-bullseye-pointer vision-mission-icon" />
                </div>
                <div className="col-xl-9 col-md-9 col-sm-9">
                  <div className="card-body">
                    <h5 className="card-title color-theme">Our Mission</h5>
                    <p className="card-text">To enable individuals to finance the infrastructure they want to see in the world.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*vision,mission sec ends here*/}
      {/*crousel sec starts here*/}
      <div className="container-fluid mt-5">
        <div className="about-carousel-heading">
          <h5 className="color-theme">Our Founding Members</h5>
        </div>
        <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="cards-wrapper">
                <div className="card d-none d-md-block about-crousel-cards">
                  <img src="E:\sunstox-new-html\assests\image\founders\Kovid.jpeg" className="card-img-top img-fluid" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title">
                      Kovid Sharma <br />
                      <span>Founder and CEO</span>
                    </h5>
                    <p className="card-text">
                      A seasoned professional and renewable energy expert, Over 20 years of experience in Renewable Energy, Telecom, and Financial Technologies in building large scale projects, running profitable
                      operations...<a href="#">more</a>
                    </p>
                  </div>
                  <div className="card-footer d-flex">
                    <a href><i className="fas fa-envelope" /></a>
                    <a href><i className="fas fa-facebook" /></a>
                    <a href><i className="fas fa-twitter" /></a>
                    <a href><i className="fas fa-linkedin" /></a>
                  </div>
                </div>
                <div className="card d-none d-md-block about-crousel-cards">
                  <img src="E:\sunstox-new-html\assests\image\founders\T1.jpg" className="card-img-top img-fluid" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title">
                      Dr. Ashima Sharma <br />
                      <span>Founder Director(Intelligence and Insights)</span>
                    </h5>
                    <p className="card-text">
                      Finding relevant experts, Competitive analysis, match making, Due Diligence, sector analysis, mix of medical knowledge and market acumen for insurance sector, Risk analysis for insurance
                      products, SME-Underwriting...<a href="#">more</a>
                    </p>
                  </div>
                  <div className="card-footer d-flex">
                    <a href><i className="fas fa-envelope" /></a>
                    <a href><i className="fas fa-facebook" /></a>
                    <a href><i className="fas fa-twitter" /></a>
                    <a href><i className="fas fa-linkedin" /></a>
                  </div>
                </div>
                <div className="card d-none d-md-block about-crousel-cards">
                  <img src="E:\sunstox-new-html\assests\image\founders\T2.jpg" className="card-img-top img-fluid" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title">
                      Vikram Shanbhag <br />
                      <span>Advisory Board(Global Business Executive | Technology | M&amp;A | Board Advisor)</span>
                    </h5>
                    <p className="card-text">
                      Global executive with proven results across an array of technology-focused companies carrying diverse leadership responsibilities. Focus on Strategy and Execution – envisioning and building
                      cohesive teams...<a href="#">more</a>
                    </p>
                  </div>
                  <div className="card-footer d-flex">
                    <a href><i className="fas fa-envelope" /></a>
                    <a href><i className="fas fa-facebook" /></a>
                    <a href><i className="fas fa-twitter" /></a>
                    <a href><i className="fas fa-linkedin" /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="cards-wrapper">
                <div className="card about-crousel-cards">
                  <img src="E:\sunstox-new-html\assests\image\founders\Kovid.jpeg" className="card-img-top img-fluid" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title">
                      Kovid Sharma <br />
                      <span>Founder and CEO</span>
                    </h5>
                    <p className="card-text">
                      A seasoned professional and renewable energy expert, Over 20 years of experience in Renewable Energy, Telecom, and Financial Technologies in building large scale projects, running profitable
                      operations...<a href="#">more</a>
                    </p>
                  </div>
                  <div className="card-footer d-flex">
                    <a href><i className="fas fa-envelope" /></a>
                    <a href><i className="fas fa-facebook" /></a>
                    <a href><i className="fas fa-twitter" /></a>
                    <a href><i className="fas fa-linkedin" /></a>
                  </div>
                </div>
                <div className="card d-none d-md-block about-crousel-cards">
                  <img src="E:\sunstox-new-html\assests\image\founders\T1.jpg" className="card-img-top img-fluid" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title">
                      Dr. Ashima Sharma <br />
                      <span>Founder Director(Intelligence and Insights)</span>
                    </h5>
                    <p className="card-text">
                      Finding relevant experts, Competitive analysis, match making, Due Diligence, sector analysis, mix of medical knowledge and market acumen for insurance sector, Risk analysis for insurance
                      products, SME-Underwriting...<a href="#">more</a>
                    </p>
                  </div>
                  <div className="card-footer d-flex">
                    <a href><i className="fas fa-envelope" /></a>
                    <a href><i className="fas fa-facebook" /></a>
                    <a href><i className="fas fa-twitter" /></a>
                    <a href><i className="fas fa-linkedin" /></a>
                  </div>
                </div>
                <div className="card d-none d-md-block about-crousel-cards">
                  <img src="E:\sunstox-new-html\assests\image\founders\T2.jpg" className="card-img-top img-fluid" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title">
                      Vikram Shanbhag <br />
                      <span>Advisory Board(Global Business Executive | Technology | M&amp;A | Board Advisor)</span>
                    </h5>
                    <p className="card-text">
                      Global executive with proven results across an array of technology-focused companies carrying diverse leadership responsibilities. Focus on Strategy and Execution – envisioning and building
                      cohesive teams...<a href="#">more</a>
                    </p>
                  </div>
                  <div className="card-footer d-flex">
                    <a href><i className="fas fa-envelope" /></a>
                    <a href><i className="fas fa-facebook" /></a>
                    <a href><i className="fas fa-twitter" /></a>
                    <a href><i className="fas fa-linkedin" /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="cards-wrapper">
                <div className="card about-crousel-cards">
                  <img src="E:\sunstox-new-html\assests\image\founders\T3.jpg" className="card-img-top img-fluid" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title">
                      Digbijaya Mahapatra <br />
                      <span>Founder and CEO</span>
                    </h5>
                    <p className="card-text">
                      Global executive with proven results across an array of technology-focused companies carrying diverse leadership responsibilities. Focus on Strategy and Execution – envisioning and building
                      cohesive teams...<a href="#">more</a>
                    </p>
                  </div>
                  <div className="card-footer d-flex">
                    <a href><i className="fas fa-envelope" /></a>
                    <a href><i className="fas fa-facebook" /></a>
                    <a href><i className="fas fa-twitter" /></a>
                    <a href><i className="fas fa-linkedin" /></a>
                  </div>
                </div>
                <div className="card d-none d-md-block about-crousel-cards">
                  <img src="E:\sunstox-new-html\assests\image\founders\T1.jpg" className="card-img-top img-fluid" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title">
                      Dr. Ashima Sharma <br />
                      <span>Founder Director(Intelligence and Insights)</span>
                    </h5>
                    <p className="card-text">
                      Finding relevant experts, Competitive analysis, match making, Due Diligence, sector analysis, mix of medical knowledge and market acumen for insurance sector, Risk analysis for insurance
                      products, SME-Underwriting...<a href="#">more</a>
                    </p>
                  </div>
                  <div className="card-footer d-flex">
                    <a href><i className="fas fa-envelope" /></a>
                    <a href><i className="fas fa-facebook" /></a>
                    <a href><i className="fas fa-twitter" /></a>
                    <a href><i className="fas fa-linkedin" /></a>
                  </div>
                </div>
                <div className="card d-none d-md-block about-crousel-cards">
                  <img src="E:\sunstox-new-html\assests\image\founders\T2.jpg" className="card-img-top img-fluid" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title">
                      Vikram Shanbhag <br />
                      <span>Advisory Board(Global Business Executive | Technology | M&amp;A | Board Advisor)</span>
                    </h5>
                    <p className="card-text">
                      Global executive with proven results across an array of technology-focused companies carrying diverse leadership responsibilities. Focus on Strategy and Execution – envisioning and building
                      cohesive teams...<a href="#">more</a>
                    </p>
                  </div>
                  <div className="card-footer d-flex">
                    <a href><i className="fas fa-envelope" /></a>
                    <a href><i className="fas fa-facebook" /></a>
                    <a href><i className="fas fa-twitter" /></a>
                    <a href><i className="fas fa-linkedin" /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="cards-wrapper">
                <div className="card about-crousel-cards">
                  <img src="E:\sunstox-new-html\assests\image\founders\T1.jpg" className="card-img-top img-fluid" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title">
                      Dr. Ashima Sharma <br />
                      <span>Founder Director(Intelligence and Insights)</span>
                    </h5>
                    <p className="card-text">
                      Finding relevant experts, Competitive analysis, match making, Due Diligence, sector analysis, mix of medical knowledge and market acumen for insurance sector, Risk analysis for insurance
                      products, SME-Underwriting...<a href="#">more</a>
                    </p>
                  </div>
                  <div className="card-footer d-flex">
                    <a href><i className="fas fa-envelope" /></a>
                    <a href><i className="fas fa-facebook" /></a>
                    <a href><i className="fas fa-twitter" /></a>
                    <a href><i className="fas fa-linkedin" /></a>
                  </div>
                </div>
                <div className="card d-none d-md-block about-crousel-cards">
                  <img src="E:\sunstox-new-html\assests\image\founders\kovid.jpeg" className="card-img-top img-fluid" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title">
                      Kovid Sharma <br />
                      <span>Founder and CEO</span>
                    </h5>
                    <p className="card-text">
                      A seasoned professional and renewable energy expert, Over 20 years of experience in Renewable Energy, Telecom, and Financial Technologies in building large scale projects, running profitable
                      operations...<a href="#">more</a>
                    </p>
                  </div>
                  <div className="card-footer d-flex">
                    <a href><i className="fas fa-envelope" /></a>
                    <a href><i className="fas fa-facebook" /></a>
                    <a href><i className="fas fa-twitter" /></a>
                    <a href><i className="fas fa-linkedin" /></a>
                  </div>
                </div>
                <div className="card d-none d-md-block about-crousel-cards">
                  <img src="E:\sunstox-new-html\assests\image\founders\T2.jpg" className="card-img-top img-fluid" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title">
                      Vikram Shanbhag <br />
                      <span>Advisory Board(Global Business Executive | Technology | M&amp;A | Board Advisor)</span>
                    </h5>
                    <p className="card-text">
                      Global executive with proven results across an array of technology-focused companies carrying diverse leadership responsibilities. Focus on Strategy and Execution – envisioning and building
                      cohesive teams...<a href="#">more</a>
                    </p>
                  </div>
                  <div className="card-footer d-flex">
                    <a href><i className="fas fa-envelope" /></a>
                    <a href><i className="fas fa-facebook" /></a>
                    <a href><i className="fas fa-twitter" /></a>
                    <a href><i className="fas fa-linkedin" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
      {/*crousel sec ends here*/}
      {/*contact sec starts here*/}
      <div className="container-fluid contact-sec mt-3">
        <img src="E:\sunstox-new-html\assests\image\SunStoX-Logo.png" className="img-fluid sunstox-main-logo" alt="logo" />
        <div className="row">
          <div className="col-sm-12 col-md-6 col-xl-4 col-lg-4">
            <h5>Contact us</h5>
            <ul style={{listStyleType: 'none'}}>
              <li className="li-type"><i className="fas fa-phone" /> &nbsp; +91 897 789 2727</li>
              <li className="li-type"><i className="fas fa-envelope" /> &nbsp; ab@gmail.com</li>
              <li className="li-type"><i className="fas fa-map-marker-alt" /> &nbsp; AKM Tower , Near Ganpati Dham Bhahdurgarh , Haryana</li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-5 col-xl-5 col-lg-4">
            <div className="row col-12">
              <div className="col-sm-6 col-md-6 col-xl-6 col-lg-6">
                <h5>Quick links</h5>
                <ul style={{listStyleType: 'none'}}>
                  <li className="li-type"><i className="fas fa-phone" /> &nbsp; Why choose us ?</li>
                  <li className="li-type"><i className="fas fa-question-circle" /> &nbsp; FAQ’s</li>
                  <li className="li-type"><i className="fas fa-paste" /> &nbsp; Privacy Policy</li>
                </ul>
              </div>
              <div className="col-sm-6 col-md-6 col-xl-6 col-lg-6 mt-4">
                <ul style={{listStyleType: 'none'}}>
                  <li className="li-type"><i className="fas fa-file-contract" /> &nbsp; Terms of Services</li>
                  <li className="li-type"><i className="fas fa-file-contract" />  &nbsp; Legal Disclaimer</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 col-xl-3 col-lg-4">
            <h5>Social links</h5>
            <ul style={{listStyleType: 'none'}} className=" d-flex">
              <li className="li-type"><i className="fas fa-envelope-open" /></li>
              <li className="li-type"><i className="fas-brands fa-facebook-f" /></li>
              <li className="li-type"><i className="fas-brands fa-twitter" /></li>
              <li className="li-type"><i className="fas-brands fa-linkedin-in" /></li>
            </ul>
          </div>
        </div>
      </div>
      {/*contact sec starts here*/}
      <footer>
        <div className="container-fluid text-center">
          <p className="mt-3">© 2022 <span className="color-theme ">Sunstox </span> | All Rights Reserved | Powered by <span className="color-theme "> Webpandits </span></p>
        </div>
      </footer>
      {/* Main content ends here */}
    </div>               
  </section>
</div>

  )
}
