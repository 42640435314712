import React from 'react'
import { AppBar, Toolbar, Box, TextField, FormControl } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "./Main.css";
import { ArrowForward } from "@material-ui/icons";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import toastr from "toastr";
import axios from "axios";
import { useState, useEffect, useContext, } from "react";
import HandleContext from '../context/HandleContext'
import Logo1 from '../images/SV_01.png'
import Cookies from 'js-cookie';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Appbar from './Appbar'

export default function Login() {
  const context = useContext(HandleContext);
  const { UserDashboard } = context;

  const [message, setMessage] = useState()
  const [alert, setAlert] = useState()


  let loginBody = document.getElementById('elements')

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Email Address is Required"),
      password: Yup.string().required("Password is Required"),
    }),
    onSubmit: async (values) => {
      console.log("form submitted", values)
      await axios.post(`https://api.sunstox.com/user/login`, JSON.stringify(values), {
        headers: {
          'Content-Type': 'application/json',
          'Client-Service': 'sunstoxAPP',
          'Auth-Key': '256901zrj4UyYF2SqzoAAZpqf67',
        }
      }).then((response) => {
        console.log(response);
        toastr.success(response.data.message)
        Cookies.set("id", response.data.uid)
        Cookies.set("num", response.data.token)
        Cookies.set("name", response.data.name)
        // setTimeout(() => {
        //   if(Cookies.get('num')){
        // console.log(Cookies.get('num'));
        navigate('/')
        // UserDashboard('dashboard')
        // console.log('got the cookie');
        //   }
        // }, 400);
        // console.log(response.data.token);
        // console.log(response.data.uid);

      }).catch((e) => {
        console.log(e);
        toastr.error(e.response.data.message)
      })

    }
  })
  let navigate = useNavigate()


  useEffect(() => {
    if(Cookies.get('num')){
      navigate('/')
    }else{
      console.log();
    }
    const queryParams = new URLSearchParams(window.location.search);
    const status = queryParams.get('s');
    const message = queryParams.get('m');
    if (status == 'success') {
      setAlert('success')
      setMessage(message)
    } else {
      setAlert('danger')
      setMessage(message)
    }
  }, [])


 
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });


  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  let login_container = document.getElementById('login_container')

  window.addEventListener("resize", function () {
    if (window.innerWidth < 600) login_container.classList.remove("container");
    if (window.innerWidth > 600) login_container.classList.add("container");
  });

  return (
    <div className='img'>
      {/* <nav className="navbar navbar-expand-lg navbar-* bg-*" style={{
        background: "rgba(255, 255, 255, 0.5)",
        border: "1px solid #ddd",
        boxShadow: "0px 2px 5px #ddd",
        top: 0,
        left: 0,
        margin: 0,
        position: "static",
      }}>
        <a className="navbar-brand" href="#">
          <img src={Logo1} width="150" height="50" className="d-inline-block align-top" alt="" />

        </a>
      </nav> */}
      <Appbar/>
      <div className="container " id='login_container'>
        <div className="row">
          <div className="col-md-12">
            <div className="card" id="cards" style={{ boxShadow: "0px 2px 5px #ddd" }}>
              <div className="card-header" style={{ backgroundColor: "#ff9c00", color: "white", fontWeight: "600", }} >
                <h3>Welcome, sign in</h3>
              </div>
              <div className="card-body" id='loginBody'>
                <div className="elements">
                  <form onSubmit={formik.handleSubmit}>
                    <TextField
                      required
                      id="standard"
                      label="Email Address"
                      name="username"
                      variant="standard"
                      // value={credentials.username}
                      sx={{ width: "100%" }}
                      // onChange={handleOnChange}
                      onChange={formik.handleChange}
                      error={formik.touched.username && Boolean(formik.errors.username)}
                      onBlur={formik.handleBlur}
                      helperText={formik.touched.username && formik.errors.username}

                    />

                    {/* #fab600  */}
                    <FormControl sx={{ width: "100%", marginTop: '15px', marginBottom: '40px' }} variant="standard" required >
                      {/* <InputLabel htmlFor="standard-adornment-password">Password</InputLabel> */}
                      <TextField
                        required
                        id="standard-adornment-password"
                        variant='standard'
                        label="Password"
                        type={values.showPassword ? "text" : "password"}
                        name="password"
                        // value={credentials.password}
                        // onChange={handleOnChange}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        onBlur={formik.handleBlur}
                        helperText={formik.touched.password && formik.errors.password}
                        InputProps={{ // <-- This is where the toggle button is added.
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />

                    </FormControl>
                    <div style={{ marginBottom: "20px", display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                      <Link to="/signup" style={{ color: "#ff9c00", width: 'fit-content', marginBottom: '10px' }}>Sign Up</Link>
                      <Link to="/forgotpassword" style={{ color: "#ff9c00", width: 'fit-content', marginBottom: '10px' }}>Forgot Password </Link>
                      <Link to="/activation" style={{ color: "#ff9c00", width: 'fit-content', marginBottom: '10px' }}>Resend account activation link </Link>
                      {message &&
                        <div className={`alert alert-${alert} alert-dismissible fade show`} role="alert">
                          <strong></strong> {message}
                          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>}                    </div>

                    <div className='buttons' >
                      <button type="submit" className="signup btn " style={{ border: "2px solid #ff9c00", textAlign: 'center', display: 'flex', justifyContent: 'center', borderRadius: "12px", padding: "7px 30px", color: "white", fontWeight: "700", boxShadow: "0px 2px 5px #ddd", outline: "none", backgroundColor: "#ff9c00", margin: 'auto' }}>
                        <div style={{ textDecoration: "none", color: "white" }}>
                          <span style={{ display: "flex" }}>Sign in<span style={{ marginLeft: "12px" }}><ArrowForward /> </span>
                          </span>
                        </div>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
