import React from 'react'
import Appbar from '../Appbar'
import { Link } from 'react-router-dom'
import img1 from '../../images/bc.jpg'
import bc2 from '../../images/im2.jpg'
import bc4 from '../../images/im3.jpg'
import web from '../../images/im4.jpg'


export default function Solar() {
  return (
   <div>
    <Appbar/>
 
<div className="head" style={{fontWeight:'bold',textAlign:'center' ,marginTop:'40px',fontSize:'35px'}}>Solar Projects</div>
<div className="linkss" style={{padding:'20px'}}>
    <Link to ="/" style={{fontSize: '30px',color: 'cadetblue',marginRight: '190px'}}>Avaliable</Link>
    <Link to ="/completed" style={{fontSize: '30px',color: 'cadetblue',marginLeft: '120px'}}>Completed</Link>
</div>
<div className="container">
        {/* <div className="row">
          <div className="col-md-6"> */}
            <div style={{display:'flex',justifyContent:'space-between',marginBottom:'50px'}}>
            <div class="card" style={{marginRight:'50px',}}>
              <img class="card-img-top" src={img1} style={{height:'50%'}}  alt="Card image cap" />
              <div class="card-body">
                <h5 class="card-title">CPOA Berghof Retirement Village | 69.12 kW | Cape Town, South Africa</h5>
                <p class="card-text">CPOA Berghof Retirement Village is the 5th retirement facility operated by the esteemed Cape Peninsula Organisation for the Aged to be going solar through the Sun Exchange platform. The CPOA Berghof Retirement Village is located in the City Bowl of Cape town and offers beautiful and well maintained gardens with mountain streams passing through, adding to a natural beauty that is already on offer. 
                </p>
              </div>
            </div>
            <div class="card" style={{}}>
              <img class="card-img-top" src={bc2} style={{height:'50%'}} alt="Card image cap" />
              <div class="card-body">
                <h5 class="card-title">Anchor Foods | 118.8 kW Solar | Maitland, Cape Town</h5>
                <p class="card-text">Anchor Foods started in 1995 at its current head office in Maitland, Cape Town. They are manufacturers of various meat products and suppliers to major retail chains, independent supermarkets and butcheries reaching both national and international markets.

Food processing technology is applied throughout the manufacturing of their products as they apply the greatest of care during the mixing, blending and packing process.
                </p>
              </div>
            </div>
            </div>
            {/* <div style={{display:'flex',justifyContent:'space-between',marginBottom:'50px' }}>
            <div class="card" style={{marginRight:'50px',width:'100%'}}>
              <img class="card-img-top" src={bc4}  style={{height:'50%'}} alt="Card image cap" />
              <div class="card-body">
                <h5 class="card-title">Tamboerskloof Primary School | 32.70 kW Solar + 14.3 kWh Storage | Cape Town, South Africa</h5>
                <p class="card-text">This will be Sun Exchange's first school solar plant equipped with a battery storage system, meaning that Tamboerskloof Primary School can keep on teaching even during the routine power-cuts that are impacting education
                </p>
              </div>
            </div>
            <div class="card" style={{width:'100%'}}>
              <img class="card-img-top" src={web} style={{height:'50%'}} alt="Card image cap" />
              <div class="card-body">
                <h5 class="card-title">Bougainvilla Retirement Village | 247.80 kW | Pretoria, South Africa</h5>
                <p class="card-text">There are retirement villages and then there’s Bougainvilla, a retirement village in Montana, a suburb in the North East of Pretoria. This retirement village promises safety, affordability, convenience and comfort for the elderly.
                </p>
              </div>
            </div>
            </div> */}
            </div>
</div>
  )
}
