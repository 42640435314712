import React from 'react'
import { useEffect, useContext, useState } from 'react'
import Appbar from '../Appbar'
// import { Player } from 'video-react'
// import 'youtube-video-js';
import GoogleMapReact from 'google-map-react';
import './Project.css'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Button } from '@mui/material';
import { ArrowForward } from "@material-ui/icons";
import Box from '@mui/material/Box';
import HandleContext from "../../context/HandleContext";
import parse from 'html-react-parser'
import Cookies from 'js-cookie';
import { makeStyles, TextField } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { Bar } from "react-chartjs-2";
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    textField: {
        marginRight: "15px",
        background: 'white',
        backgroundColor: 'white'
    },
    input: {
        color: "grey",
        backgroundColor: 'white'
        //   padding:'10px 5px 0px 5px'
    },
    field: {
        color: 'yellow'
    },

}));



export default function InfoCard() {

    const navigate = useNavigate()

    const [credentials, setCredentials] = useState({ no_of_cells: "1" })
    const [loginDetails, setloginDetails] = useState({username:"",password:""})

    const onChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value })
    }

    const classes = useStyles();

    const context = useContext(HandleContext);
    const { ProjectDetails, details ,Login} = context;
    const [login, setLogin] = useState()
    const [id, setId] = useState()

    useEffect(() => {
        // console.log('Detail project page');
        ProjectDetails(Cookies.get('ProjectId'))
        Cookies.get('id')?setLogin(true):setLogin(false)
    }, [])

    let project_income = [];

 

    details ? details.income.forEach((e) => {
        // console.log(e)
        project_income.push(e * credentials.no_of_cells);
    }) :
        console.log("nothing to print")

        const handleOnChange=(e)=>{
            setloginDetails({ ...loginDetails, [e.target.name]: e.target.value })
        }

        const handleOnSubmit= async(e)=>{
            e.preventDefault();
          await Login(JSON.stringify(loginDetails))
          setLogin(true)
          await Cookies.get('id')
            setId(Cookies.get('id'))
            console.log('id',id);
            window.location.reload();
        }
    
    const percentage = details && details.sold_per;
    return (
        <>
            {/* <!-- Button trigger modal --> */}
        
            {/* <!-- Modal --> */}
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Sign In, Please</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form onSubmit={handleOnSubmit}>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Email address</label>
                                    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" name='username' value={loginDetails.username} onChange={handleOnChange} required/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Password</label>
                                    <input type="password" className="form-control" id="exampleInputPassword1"  name='password' value={loginDetails.password} onChange={handleOnChange} required/>
                                </div>
                              
                                <button type="submit" className="btn btn-primary" >Sign In</button>
                            </form>

                        </div>
                        {/* <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Save changes</button>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className='detail_box' >
                <div style={{ textAlign: 'normal', fontSize: '22px', marginBottom: '40px', color: 'grey' }}>| <span style={{ color: "cornflowerblue", marginLeft: '10px' }}> Now Available</span></div>
                <div className="card" >
                    <div className="card-header" style={{ textAlign: 'normal', display: 'flex', justifyContent: 'space-between' }}>
                        <div className="zar" style={{ display: 'flex', flexDirection: 'column' }}>
                            <h4 className='info-value'>{details && details.currency} {details && details.rate_cell} </h4>
                            <span className='info-label'> per cell</span>
                        </div>
                        <div className="btc" style={{ display: 'flex', flexDirection: 'column' }}>
                            <span className='info-value' >Total Cell</span>
                            <span className='info-label'>{details && details.total_cells}</span>
                        </div>
                    </div>
                    <div className="card-body">
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className="value" style={{ display: 'flex', flexDirection: 'column' }}>
                                <h4 className='info-value'>Avaliable</h4>
                                <span className='info-label'>{details && (details.total_cells - details.sold_cell)}</span>
                            </div>
                            <div className="value" style={{ display: 'flex', flexDirection: 'column' }}>
                                <span className='info-value' >Sold</span>
                                <span className='info-label'>{details && details.sold_cell}</span>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                            <div style={{ width: 200, height: 200, }}>
                                <CircularProgressbar
                                    value={percentage}
                                    text={`${percentage}% Sold`}
                                    styles={buildStyles({
                                        // Rotation of path and trail, in number of turns (0-1)
                                        rotation: 0.25,

                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                        strokeLinecap: 'butt',

                                        // Text size
                                        textSize: '16px',
                                        fontWeight: 'bold',
                                        display: 'flex',
                                        flexDirection: 'column',

                                        // How long animation takes to go from one percentage to another, in seconds
                                        pathTransitionDuration: 0.5,

                                        // Can specify path transition in more detail, or remove it entirely
                                        // pathTransition: 'none',

                                        // Colors
                                        pathColor: `#fab600`,
                                        // pathColor: `rgba(62, 152, 199, ${percentage / 100})`,
                                        textColor: 'black',
                                        trailColor: '#d6d6d6',
                                        backgroundColor: '#3e98c7',
                                    })}
                                />
                            </div>
                        </div>
                        {/* <div style={{ textAlign: 'center', marginTop: '30px' }}>
                                        <button className='btn' style={{ padding: '10px 20px', backgroundColor: '#fab600', outline: 'none', background: '#fab600', color: 'white', fontWeight: '500', margin: '500' }}><Link to="/pay" style={{color:'white'}}>BUY SOLAR CELLS NOW <span style={{ marginLeft: '10px' }}> <ArrowForward /></span></Link></button>
                                    </div> */}
                        <div className="buyers_information" style={{ margin: '30px 0px', fontSize: '14px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div className="info">
                                <div className="buyers_number">450</div>
                                <div className="buyers" style={{ marginLeft: '40px' }}>Number of buyers</div>
                            </div>
                            <div className="info">
                                <div className="buyers_number">15 Sep 2022</div>
                                <div className="buyers">Crowdsale end date</div>
                            </div>
                        </div>
                        {/* <div style={{ textAlign: 'center', marginTop: '30px' }}>
                            <button className='btn pdfbtn' style={{ padding: '10px 20px', boxShadow: "0px 2px 5px #ddd", borderRadius: '10px', fontSize: '14px', backgroundColor: 'WHITE', outline: 'none', background: 'white', border: '2px solid #fab600', color: 'grey', fontWeight: '500', margin: '500' }}>PROJECT INFORMATION (PDF) </button>
                        </div> */}
                    </div>
                </div>
                <div className="card" style={{ marginBottom: '20px' }}>
                    <div className="card-header">
                        <div style={{ textAlign: 'center', color: 'grey', fontWeight: '600', fontSize: '14px', }}>Estimate your earnings and impact</div>
                        <div className='layout'>
                            <div className='' style={{ marginTop: '30px' }}>
                                <TextField
                                    label="Internal Rate of Return (%)"
                                    id="filled-read-only-input"
                                    defaultValue="Connection Interrupted"
                                    value={details && details.irr}
                                    variant="filled"
                                    style={{ width: '100%' }}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </div>
                            <form action='https://api.sunstox.com/razorpay' method='post'>
                                <div className='layout_box'>
                                    <TextField
                                        label="Number Of Cells"
                                        id="filled-size-normal"
                                        name='no_of_cells'
                                        defaultValue="1"
                                        value={credentials.no_of_cells}
                                        variant="filled"
                                        onChange={onChange}
                                        className={classes.textField}
                                        InputProps={{
                                            className: classes.input
                                        }}

                                    />
                                    <TextField
                                        label="Total Spend (INR)"
                                        id="filled-read-only-input"
                                        defaultValue="Connection Interrupted"
                                        value={details && (credentials.no_of_cells * details.rate_cell)}
                                        variant='filled'
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                    <input type="hidden" name="user_id" value={Cookies.get('id')} />
                                    <input type="hidden" name="project_id" value={details && details.id} />
                                    <input type="hidden" name="return_url" value="http://localhost:3000/userprofile" />
                                </div>

                                <div className='layout_box'>
                                    <TextField
                                        label="Year 1 energy yield (kWh)"
                                        id="filled-read-only-input"
                                        defaultValue="Connection Interrupted"
                                        value={details && (credentials.no_of_cells * details.energy).toFixed(2)}
                                        variant="filled"
                                        className={classes.textField}
                                        InputProps={{
                                            className: classes.input,
                                            readOnly: true
                                        }}

                                    />
                                    <TextField
                                        label="CO2 reduction (kg)" 
                                        id="filled-read-only-input"
                                        defaultValue="Connection Interrupted"
                                        value={details && (details.co2_reduction * credentials.no_of_cells)}
                                        variant="filled"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                    {/* <span style={{marginLeft:'8px'}} title="Tooltip on top" className='tool'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                    </span> */}
                                </div>

                                {login===true ?
                                    <div style={{ textAlign: 'center', marginTop: '30px' }}>
                                        <button type={Cookies.get('id') && 'submit'} className='btn' style={{ padding: '10px 20px', backgroundColor: '#fab600', outline: 'none', background: '#fab600', color: 'white', fontWeight: '500', margin: '500' }} onClick={async()=>{
                                            if(Cookies.get('id')){
                                                console.log('got it');
                                                console.log(Cookies.get('id'));
                                            }
                                        }} >BUY SOLAR CELLS NOW <span style={{ marginLeft: '10px' }} > <ArrowForward /></span></button>
                                    </div> : <div style={{ textAlign: 'center', marginTop: '30px' }}>
                                        <button type='button' className='btn' data-toggle="modal" data-target="#exampleModal" style={{ padding: '10px 20px', backgroundColor: '#fab600', outline: 'none', background: '#fab600', color: 'white', fontWeight: '500', margin: '500' }} >BUY SOLAR CELLS NOW<span style={{ marginLeft: '10px' }} > <ArrowForward /></span></button>
                                    </div>}



                            </form>
                        </div>
                        <div className='graph' style={{ margin: '20px 0px', height: "250px" }} >
                            <Bar
                                data={{
                                    // Name of the variables on x-axies for each bar
                                    // labels: ["1", "2", "3", "4", "5"],
                                    labels: project_income.map((e, index) => {
                                        return [index + 1]
                                    }),
                                    datasets: [
                                        {
                                            // Label for bars
                                            label: `Yearly Estimate (${details && details.currency}) `,
                                            // Data or value of your each variable

                                            // data: [details && details.income[[0]]],

                                            // data: details && details.income.map((e)=>
                                            // { return [e] }),
                                            data: project_income,
                                            // Color of each bar
                                            backgroundColor: ["#fab600", "#fab600", "#fab600", "#fab600"],
                                            // Border color of each bar
                                            borderColor: ["#fab600", "#fab600", "#fab600", "#fab600"],
                                            borderWidth: 0.5,
                                        },
                                    ],
                                }}
                                // Height of graph
                                height={400}
                                options={{
                                    maintainAspectRatio: false,
                                    scales: {
                                        yAxes: [
                                            {
                                                ticks: {
                                                    // The y-axis value will start from zero
                                                    beginAtZero: true,
                                                },
                                            },
                                        ],
                                    },
                                    legend: {
                                        labels: {
                                            fontSize: 5,
                                        },
                                    },
                                }}
                            />
                        </div>
                        <div style={{ fontSize: '12px', color: 'grey', fontStyle: 'oblique', textAlign: 'center' }}> Year - Hover over graph for income details</div>

                        <div className="income_info">

                            <div className="rate">{details && details.energy_rate} {details && details.currency}
                                {/* <span className="moreInfo">~ 0.07 USD</span> */}
                            </div>

                            <div className="rate_info">1st year income rate per kWh (Gross)</div>
                        </div>
                        <div className="income_info">
                            <div className="rate">{details && (details.income_cell * credentials.no_of_cells)} {details && details.currency}
                                {/* <span className="moreInfo">~ 0.39 USD</span> */}
                            </div>
                            <div className="rate_info">1st year income for cells (Gross)*</div>
                        </div>
                        <div className="income_info">
                            <div className="rate">{details && details.total_income} {details && details.currency}
                                {/* <span className="moreInfo">~ 12.14 USD</span> */}
                            </div>
                            <div className="rate_info">Total {details && details.tenure} year income for cells (Net)*</div>
                        </div>

                       
                    </div>
                
                </div>
            </div>
        </>
    )
}
