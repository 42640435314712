import React from 'react'
import Appbar from '../Appbar'
import { useContext, useEffect } from 'react';
import HandleContext from '../../context/HandleContext';


export default function Payment_Generated() {
    let count=1;

    let context=useContext(HandleContext)
    const { PaymentHistory, paymentHistory} = context;
    useEffect(() => {
        PaymentHistory("payout_generation")
    }, [])

// paymentHistory ? console.log(paymentHistory) : console.log('nothing to peint');

  return (
    <>
  <Appbar />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card" style={{marginTop:'20px'}}>
                            <div className="card-header card-secondary" style={{color:'grey'}}>Payout Generation</div>
                            <div className="card-body">
                            <table className="table table-striped table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">S. No.</th>
                                        <th scope="col">Project Title</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Month</th>
                                        <th scope="col">Generated Date</th>
                                       
                                    </tr>
                                </thead>
                                <tbody>
                                    {paymentHistory && paymentHistory.map((e)=>{
                                        return <tr>
                                         <th scope="row">{count++}</th>
                                         <td>{e.project_title}</td>
                                         <td>{e.amount}</td>
                                         <td>{e.month}</td>
                                         <td>{e.gen_date}</td>
                                     </tr>
                                    })}
                                   
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}
